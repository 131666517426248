const data = [
    { value: 1, name: 'Enero' },
    { value: 2, name: 'Febrero' },
    { value: 3, name: 'Marzo' },
    { value: 4, name: 'Abril' },
    { value: 5, name: 'Mayo' },
    { value: 6, name: 'Junio' },
    { value: 7, name: 'Julio' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Septiembre' },
    { value: 10, name: 'Octubre' },
    { value: 11, name: 'Noviembre' },
    { value: 12, name: 'Diciembre' }
]

const date = {
    GetWeekNumber(d) {
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));

        return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
    },
    GetWeekNumberString(date) {
        var parts = date.toString().split('-');
        var d = new Date(parts[0], parts[1] - 1, parts[2]);
        var weekNumber = this.GetWeekNumber(d);
        var year = d.getFullYear().toString();
        var maskYear = year.substring(year.length - 2, year.length);
        var maskWeek = weekNumber.toString().padStart(2, '0');

        return `${maskYear}${maskWeek}`;
    },
    GetWeekNumberDate(date) {
        var weekNumber = this.GetWeekNumber(date);
        var year = date.getFullYear().toString();
        var maskYear = year.substring(year.length - 2, year.length);
        var maskWeek = weekNumber.toString().padStart(2, '0');

        return `${maskYear}${maskWeek}`;
    },
    months: data,
    CreateYears(min, max) {
        let years = [];
        for (let index = min; index < max; index++) {
            years.push({
                value: index,
                name: index
            });
        }

        return years;
    },
    GetDate(date, separator) {
        let array = date.split(separator);
        return { getFullYear: array[0], getMonth: array[1], getDay: array[2] };

    },
    GetFormat(date) {
        return String(date.getDate()).padStart(2, '0') + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + date.getFullYear() + " ";
    },
    GetFormatDate(date) {
        return date.getFullYear() +  "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0') ;
    }
}

export default date;