import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import IdleVue from 'idle-vue';
import common from './common'
import AppAuthorize from './appAuthorize';
import Axios  from 'axios';
import VTooltip  from 'v-tooltip'

require('dotenv').config();

const eventsHub = new Vue();
Vue.config.productionTip = false;
Vue.directive('appAuthorize', AppAuthorize);
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
Vue.mixin(common);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 6000000,
  startAtIdle: false
});

Vue.use(VTooltip);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
