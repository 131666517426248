<template id="notifications">
  <div class="notifications">
    <notification
      v-for="(notification, i) in notifications" :key="i"
      :notification="notification"
      @close-notification="removeNotification"
      transition="fade"
    >
    </notification>
  </div>
</template>

<script>
import Notification from "../utils/notification.vue";
import NotificationStore from "../utils/notificationStore";


export default {
  template: "#notifications",
  components: {
    notification: Notification,
  },
  data() {
    return {
      notifications: NotificationStore.state,
    };
  },
  methods: {
    removeNotification: function(alert) {
      NotificationStore.removeNotification(alert);
    },
  },
};
</script>
