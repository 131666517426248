import store from '../store';
import authorization from './authorization';

export default function RedirectIfAuthenticated(router) {
    router.beforeEach((to, from, next) => {

        if (to.matched.some(record => record.meta.requireAuthentication)) {
            if (!store.getters.isAuthenticated) {
                next("/login")
            } else {
                let authorized = authorization.authorize(to.meta.requireAuthentication, to.meta.requiredPermissions);

                if (authorized !== "authorized") {
                    next("/")
                } else {
                    next()
                }
            }
        } else {
            next()
        }

        //const authPages = ['/login', '/'];
        //const authRequired = !authPages.includes(to.path);

        /*
                let pathArray = to.path.split('/')
                if (pathArray.some(e => e === 'users')) {
                    next();
                    return;
                }
                /*
                        if(to.path.indexOf('users')){
                            next();
                            return;
                        }
                */
        /*
         if (!store.getters.isAuthenticated) {
             next('/login');

             return;
         }

         if (authRequired && !store.getters.isAuthenticated) {
             next('/');

             return;
         }

         if (store.getters.isAuthenticated && authPages.some(page => page == to.path)) {
             next('/home');

             return;
         }

         next();
         */
    })
}