<script>
export default {
  data() {
    return {
      imagePreviewURL: null,
    };
  },
  methods: {
    onFileChange(payload) {
      const file = payload;

      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file);
      } else {
        this.imagePreviewURL = null;
      }
    },
    clear() {
      this.imagePreviewURL = null;
    },
  },
};
</script>
