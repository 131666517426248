<template>
  <v-app-bar flat dark app dense>
    <v-app-bar-nav-icon
      @click="toggleNavigationBar"
      v-if="
        this.$store.getters.isAuthenticated &&
          this.$store.getters.isFinishReading
      "
    ></v-app-bar-nav-icon>
    <v-toolbar-items
      class="hidden-sm-and-down"
      v-if="
        this.$store.getters.isAuthenticated &&
          this.$store.getters.isFinishReading
      "
    >
      <template v-for="(menuItem, i) in menu">
        <v-btn
          :key="i"
          :to="menuItem.path"
          text
          v-if="menuItem.visible && menuItem.isToolbar"
          v-appAuthorize="menuItem.authorize"
          >{{ menuItem.name }}
        </v-btn>
      </template>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-btn
      text
      @click="handleLogin"
      v-if="
        !$route.name == '/' && !this.$store.getters.isAuthenticated
      "
    >
      <v-icon>account_circle</v-icon>
      <span class="mr-2">Iniciar Sesión</span>
    </v-btn>
    <template>
      <v-btn
        @click.stop="dialog = true"
        text
        v-if="this.$store.getters.isAuthenticated"
      >
        <span class="mr-2">CERRAR SESIÓN</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            ¿Desea cerrar la sesión?
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="handleLogout">
              Aceptar
            </v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-app-bar>
</template>

<script>
import { AUTH_LOGOUT } from "../../store/actions/auth";
import { mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
  }),
  computed: mapState({
    menu: (state) => state.user.menu,
  }),
  methods: {
    handleLogout() {
      this.dialog = false;
      this.$store.dispatch(AUTH_LOGOUT);
    },
    handleLogin() {
      this.$router.push("/login");
    },
    toggleNavigationBar() {
      const vm = this;

      vm.$emit("toggleNavigationBar");
    },
  },
};
</script>

<style></style>
