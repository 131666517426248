<template>
  <v-container fluid>
    <v-row dense>
      <v-col align-self="start">
        <h2>Reporte de participantes</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col align-self="start">
        <v-btn color="primary" dark right @click="search()">
          Buscar
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="table-responsive py-5">
      <v-data-table
        :headers="headers"
        :items="data"
        sort-by="name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import { setInteractionMode } from "vee-validate";
import userService from "../../../core/http/user.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";

setInteractionMode("eager");

export default {
  data: () => ({
    headers: [
      {
        text: "Total participantes",
        align: "center",
        value: "total",
      },
      { text: "Total mujeres", value: "tmujeres", align: "center" },
      { text: "Total hombres", value: "thombres", align: "center" },
      { text: "Total publicadores", value: "tpublicador", align: "center" },
      {
        text: "Total precursores",
        value: "tprecursores",
        align: "center",
      },
      { text: "Total especiales", value: "tespecial", align: "center" },
      {
        text: "Total misioneros",
        value: "tmisionero",
        align: "center",
      },
      { text: "Total siervos ministeriales", value: "tsm", align: "center" },
      { text: "Total ancianos", value: "tanciano", align: "center" },
      { text: "Total viajantes", value: "tsc", align: "center" },
    ],
    data: [],
    fields: [
      "total",
      "thombres",
      "tmujeres",
      "tpublicador",
      "tprecursores",
      "tespecial",
      "tmisionero",
      "tsm",
      "tanciano",
      "tsc",
    ],
    titles: {
      total: "Total participantes",
      tmujeres: "Total mujeres",
      thombres: "Total hombres",
      tpublicador: "Total publicadores",
      tprecursores: "Total precursores",
      tespecial: "Total especiales",
      tmisionero: "Total misioneros",
      tsm: "Total siervos ministeriales",
      tanciano: "Total ancianos",
      tsc: "Total viajantes",
    },
    showButtonExport: false,
  }),

  components: {},

  computed: {
  },

  watch: {},

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.data = [];
      var data = { id_ciudad: userService.getCity() };
      var json = qs.stringify({ json: JSON.stringify(data) });
      await reportService.reporte_1(json).then((response) => {
        if (!Array.isArray(response.data.informe)) {
          this.data.push(response.data.informe);
        } else {
          this.data = response.data.informe;
        }
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, 'value', 'text');
      factory.CreateExcel(this.data, this.fields, this.titles, "reporte_participantes");
    },
  },
};
</script>

<style lang="scss" scoped></style>
