import { USER_REQUEST, USER_REQUEST_TEST, USER_ERROR, USER_SUCCESS, PERMISSIONS, MENU, INDEX } from "../actions/user.js";
import { AUTH_LOGOUT } from "../actions/auth.js";
import apiCall from "../../core/utils/api.js";
import Vue from "vue";
import menu from "../../core/utils/menu";
import userService from "../../core/http/user.service.js";

const state = { status: "", profile: {}, isLoaderProfile: false, city: null, id: null };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.user,
  isFinishReading: state => state.isLoaderProfile,
  userPermissions: state => state.userPermissions,
  menu: state => state.menu,
  indice: state => state.indice,
  indiceReport: state => state.indiceReport,
  getCity: state => state.profile.id_ciudad,
  getRol: state => state.profile.id_rol,
  getUserIdentifier: state => state.profile.id,
  getParticipantId : state => state.profile.id_participante
};

const actions = {
  [USER_REQUEST_TEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    apiCall({ url: "user/me" })
      .then(response => {
        localStorage.setItem('user-permissions', JSON.stringify(response.permissions));
        commit(INDEX, { indice: "indice", data: menu.makeIndex("Formularios") });
        commit(INDEX, { indice: "indiceReport", data: menu.makeIndex("Reportes") });
        commit(MENU, menu.makeMenu());
        commit(USER_SUCCESS, response);
        commit(PERMISSIONS, response.permissions);
      })
      .catch(() => {
        commit(USER_ERROR);
        dispatch(AUTH_LOGOUT);
      });
  },
  [USER_REQUEST]: async ({ commit, dispatch }, userId) => {
    commit(USER_REQUEST);
    try {
      let response = await userService.getbyId(userId);
      const usuario = response.data.usuario;
      localStorage.setItem('auth-user', JSON.stringify(response.data.usuario));
      commit(USER_SUCCESS, usuario);
      response = await userService.accesacomp(usuario.id_rol);
      localStorage.setItem('user-permissions', JSON.stringify(response.data.accesos));
      commit(INDEX, { indice: "indice", data: menu.makeIndex("Formularios") });
      commit(INDEX, { indice: "indiceReport", data: menu.makeIndex("Reportes") });
      commit(MENU, menu.makeMenu());
      commit(PERMISSIONS, response.data.accesos);

      return response;
    } catch (err) {
      commit(USER_ERROR);
      dispatch(AUTH_LOGOUT);
    }
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.isLoaderProfile = false;
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.isLoaderProfile = false;
    state.profile = {};
  },
  [PERMISSIONS]: (state, resp) => {
    state.isLoaderProfile = true;
    Vue.set(state, "userPermissions", resp);
  },
  [MENU]: (state, resp) => {
    Vue.set(state, "menu", resp);
  },
  [INDEX]: (state, resp) => {
    Vue.set(state, resp.indice, resp.data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};