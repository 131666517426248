export default {
    GetDiasDisponibles(disponibilidadNumerica) {
        const horariosDisponibles = ["Mañana", "Tarde", "Noche", "Medio día", "Todo el día"];

        let disponibilidadNumericaString = disponibilidadNumerica.toString();
        let diasDisponiblesArray = []
        let disponibilidadToReturn = ""
        disponibilidadNumericaString = disponibilidadNumericaString.split("").reverse().join("");
        for (let letterPosition = 0; letterPosition < disponibilidadNumericaString.length; letterPosition++) {
            if (disponibilidadNumericaString.charAt(letterPosition) == "1") {
                diasDisponiblesArray.push(horariosDisponibles[letterPosition])
            }
        }
        if (diasDisponiblesArray.length == 0) {
            disponibilidadToReturn = "-"
        } else {
            for (let i = 0; i < diasDisponiblesArray.length; i++) {
                if (i == 0) {
                    disponibilidadToReturn += diasDisponiblesArray[i]
                } else {
                    disponibilidadToReturn += ", " + diasDisponiblesArray[i]
                }
            }
        }

        return disponibilidadToReturn
    }
}