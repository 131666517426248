import axios from 'axios';
import store from '../store'
import { AUTH_LOGOUT, REFRESH_TOKEN_TEST } from '../store/actions/auth'
import { EventBus } from '../core/utils/eventhub';
import NotificationStore from '../core/utils/notificationStore';

axios.interceptors.request.use(
    conf => {
        EventBus.$emit('toggle-loader', true);

        if (store.getters.isAuthenticated) {
            let token = localStorage.getItem('token');

            if (token) {
                conf.headers['Authorization'] = `${token}`;
            }
        }

        return conf;
    },

    error => {
        EventBus.$emit('toggle-loader', false);

        return Promise.reject(error);
    }

);

axios.interceptors.response.use(
    response => {
        if(response.isAxiosError){
            NotificationStore.addNotification({
                title: "Error !!!",
                type: "error",
                text:`Code error: ${response.message}`,
                timeout: true,
            });
        }

        EventBus.$emit('toggle-loader', false);

        return response;
    },
    error => {
        EventBus.$emit('toggle-loader', false);

        if (error.response.status == 401) {
            NotificationStore.addNotification({
                text: "401 Unauthorized",
                type: "info",
                timeout: true,
            });
            this.$store.dispatch(REFRESH_TOKEN_TEST).catch(() => this.$router.push("/login"));
        }
        if (error.response.status == 403) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
        } if (error.response.status == 404) {
            let message = error.response.data != null ? error.response.data.mensaje : error.response.statusText;

            NotificationStore.addNotification({
                title: "Error !!!",
                type: "error",
                text:`Code error: ${error.response.status}. ${message || ''}`,
                timeout: true,
            });
        }
        else {
            NotificationStore.addNotification({
                title: "Error !!!",
                type: "error",
                text: `Code error: ${error.response.status}. ${error.response.data.message || ''}`,
                timeout: true,
            });
        }

        return Promise.reject(error);
    }
);



