import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/auth/login.vue';
import './interceptors.js'
import middleware from './middleware'
import PageNotFound from '../components/pageNotFound';
import Formularios from '../components/forms/index';
import Ausencias from '../components/forms/ausencias';
import Capacitaciones from '../components/forms/capacitaciones';
import Circuitos from '../components/forms/circuitos';
import Ciudades from '../components/forms/ciudades';
import Eventos from '../components/forms/eventos';
import Experiencias from '../components/forms/experiencias';
import Participantes from '../components/forms/participantes';
import Horarios from '../components/forms/horarios';
import Informes from '../components/forms/reportes';
import Reportes from '../components/forms/indexReport';
import Roles from '../components/forms/roles';
import Turnos from '../components/forms/turnos';
import Ubicaciones from '../components/forms/ubicaciones';
import Usuarios from '../components/forms/usuarios';
import Home from '../components/forms/homeIndex';
import Asignaciones from '../components/forms/asignaciones'
import Reporte1 from '../components/forms/reports/report1';
import Reporte2 from '../components/forms/reports/report2';
import Reporte4 from '../components/forms/reports/report4';
import Reporte7 from '../components/forms/reports/report7';
import Reporte9 from '../components/forms/reports/report9';
import Reporte10 from '../components/forms/reports/report10';
import Reporte12 from '../components/forms/reports/report12';
import Reporte13 from '../components/forms/reports/report13';
import user from '../components/forms/images/user';
//import Recordatorios from '../components/forms/recordatorios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/users/:id',
    component: user
  },
  {
    path: '*',
    component: PageNotFound
  }, {
    path: '/',
    name: 'index',
    component: Home,
    meta: {
      requireAuthentication: true,
    }
  }, {
    path: '/home',
    name: 'Home',
    component: Formularios,
    meta: {
      requireAuthentication: true,
      //requiredPermissions: 'menu:recordatorios',
    }
  },
  {
    path: '/Formularios',
    name: 'Formularios',
    component: Formularios,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'menu:formularios',
    }
  },
  /*
  {
    path: '/recordatorios',
    name: 'Recordatorios',
    component: Recordatorios,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'menu:recordatorios',
    }
  },
  */
  {
    path: '/Asignaciones',
    name: 'Asignaciones',
    component: Asignaciones,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:asignaciones',
    }
  }, {
    path: '/Ausencias',
    name: 'Ausencias',
    component: Ausencias,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:ausencias',
    }
  }, {
    path: '/Capacitaciones',
    name: 'Capacitaciones',
    component: Capacitaciones,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:capacitaciones',
    }
  }, {
    path: '/Circuitos',
    name: 'Circuitos',
    component: Circuitos,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:circuitos',
    }
  }, {
    path: '/Ciudades',
    name: 'Ciudades',
    component: Ciudades,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:ciudades',
    }
  }, {
    path: '/Eventos',
    name: 'Eventos',
    component: Eventos,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:eventos',
    }
  }, {
    path: '/Experiencias',
    name: 'Experiencias',
    component: Experiencias,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:experiencias',
    }
  }, {
    path: '/Participantes',
    name: 'Participantes',
    component: Participantes,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:participantes',
    }
  }, {
    path: '/Horarios',
    name: 'Horarios',
    component: Horarios,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:horarios',
    }
  }, {
    path: '/Roles',
    name: 'Roles',
    component: Roles,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:roles',
    }
  }, {
    path: '/Turnos',
    name: 'Turnos',
    component: Turnos,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:turnos',
    }
  }, {
    path: '/Ubicaciones',
    name: 'Ubicaciones',
    component: Ubicaciones,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:ubicaciones',
    }
  }, {
    path: '/Usuarios',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:usuarios',
    }
  }, {
    path: '/Reportes',
    name: 'Reportes',
    component: Reportes,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:reportes',
    }
  },
  {
    path: '/Informes',
    name: 'Informes',
    component: Informes,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'formulario:informes',
    }
  }, {
    path: '/login',
    name: 'login',
    component: Login,
  }, {
    path: '/Reporte_participantes',
    name: 'ReporteTotalesParticipantes',
    component: Reporte1,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:totales:particpantes',
    }
  },
  {
    path: '/Reporte_asignados',
    name: 'ReporteParticipanteAsignados',
    component: Reporte2,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:particpantes:asignados',
    }
  },
  {
    path: '/Reporte_ausencias',
    name: 'ReportePartipantesAusencias',
    component: Reporte4,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:participantes:ausencias',
    }
  },
  {
    path: '/Reporte_informes',
    name: 'ReporteInformesServicio',
    component: Reporte7,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:informes:servicio',
    }
  },
  {
    path: '/Reporte_totales_capacitaciones',
    name: 'ReporteInformesCapacitaciones',
    component: Reporte9,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:informes:totalescapacitaciones',
    }
  },
  {
    path: '/Reporte_capacitaciones',
    name: 'ReporteCapacitaciones',
    component: Reporte10,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:informes:capacitaciones',
    }
  },
  {
    path: '/Reporte_participantes_punto',
    name: 'ReporteCapitanes',
    component: Reporte12,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:informes:capitanes',
    }
  },
  {
    path: '/Reporte_disponibilidad_turnos',
    name: 'ReporteTurnos',
    component: Reporte13,
    meta: {
      requireAuthentication: true,
      requiredPermissions: 'reporte:informes:turnos',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

middleware(router);

export default router


