import Axios from 'axios';

const RESOURCE_NAME_REPORT_1 = "/reporte1";
const RESOURCE_NAME_REPORT_2 = "/reporte2";
const RESOURCE_NAME_REPORT_4 = "/reporte4";
const RESOURCE_NAME_REPORT_7 = "/reporte7";
const RESOURCE_NAME_REPORT_9 = "/reporte9";
const RESOURCE_NAME_REPORT_10 = "/reporte10";
const RESOURCE_NAME_REPORT_12 = "/reporte12";
const RESOURCE_NAME_REPORT_13 = "/reporte13";
const RESOURCE_NAME_REPORT_6 = "/reporte6";

export default {
    reporte_1(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_1}?${data}`);
    },
    reporte_2(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_2}?${data}`);
    },
    reporte_4(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_4}?${data}`);
    },
    reporte_7(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_7}?${data}`);
    },
    reporte_9(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_9}?${data}`);
    },
    reporte_10(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_10}?${data}`);
    },
    reporte_12(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_12}?${data}`);
    },
    reporte_13(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_13}?${data}`);
    },
    reporte_6(data) {
        return Axios.get(`${RESOURCE_NAME_REPORT_6}?${data}`);
    }
}