const mocks = {
  "auth": {
    POST:
    {
      name: "Luis", email: "lfga_1991@hotmail.com",
      token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MTU4NDAzMDIsImV4cCI6MTY0NzM3NjMwMiwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.IwooMVT2IkS-H0J6-KH5wH6Houb2dDo4fksBeInI4rg",
    }
  },
  "user/me": {
    GET: {
      name: "Luis Felipe Garcia", congregation: 'Miramar', email: "lfga_1991@hotmail.com", permissions: [{
        id_rol: 1,
        id_component: 1,
        visible: true,
        component: {
          id: 1, name: 'Recordatorios', hrf: 'sec_record'
        }
      }, {
        id_rol: 1,
        id_component: 2,
        visible: true,
        component: {
          id: 2, name: 'Formularios', hrf: 'sec_form'
        }
      }, {
        id_rol: 1,
        id_component: 2,
        visible: true,
        component: {
          id: 2, name: 'Participantes', hrf: 'form_participantes'
        }
      },
      {
        id_rol: 1,
        id_component: 3,
        visible: true,
        component: {
          id: 3, name: 'Ciudades', hrf: 'form_ciudades'
        }
      },
      {
        id_rol: 1,
        id_component: 4,
        visible: true,
        component: {
          id: 4, name: 'Circuitos', hrf: 'form_circuitos'
        }
      },
      {
        id_rol: 1,
        id_component: 5,
        visible: true,
        component: {
          id: 5, name: 'Roles de Seguridad', hrf: 'form_roles'
        }
      },
      {
        id_rol: 1,
        id_component: 6,
        visible: true,
        component: {
          id: 6, name: 'Horarios', hrf: 'form_horarios'
        }
      },
      {
        id_rol: 1,
        id_component: 7,
        visible: true,
        component: {
          id: 7, name: 'Ubicaciones', hrf: 'form_ubicaciones'
        }
      },
      {
        id_rol: 1,
        id_component: 8,
        visible: true,
        component: {
          id: 8, name: 'Turnos', hrf: 'form_turnos'
        }
      },
      {
        id_rol: 1,
        id_component: 9,
        visible: true,
        component: {
          id: 9, name: 'Eventos', hrf: 'form_eventos'
        }
      },
      {
        id_rol: 1,
        id_component: 10,
        visible: true,
        component: {
          id: 10, name: 'Capacitaciones', hrf: 'form_capacitaciones'
        }
      },
      {
        id_rol: 1,
        id_component: 11,
        visible: true,
        component: {
          id: 11, name: 'Reportes', hrf: 'form_reportes'
        }
      },
      {
        id_rol: 1,
        id_component: 12,
        visible: true,
        component: {
          id: 12, name: 'Ausencias', hrf: 'form_ausencias'
        }
      },
      {
        id_rol: 1,
        id_component: 13,
        visible: true,
        component: {
          id: 13, name: 'Experiencias', hrf: 'form_experiencias'
        }
      },
      {
        id_rol: 1,
        id_component: 14,
        visible: true,
        component: {
          id: 14, name: 'Usuarios', hrf: 'form_usuarios'
        }
      },
      {
        id_rol: 1,
        id_component: 15,
        visible: true,
        component: {
          id: 15, name: 'Reportes', hrf: 'sec_reportes'
        }
      },
      {
        id_rol: 1,
        id_component: 16,
        visible: true,
        component: {
          id: 16, name: 'Configuraciones', hrf: 'sec_configuraciones'
        }
      }]
    }
  },
  'token': {
    GET: {
      token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MTU4NDAzMDIsImV4cCI6MTY0NzM3NjMwMiwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.IwooMVT2IkS-H0J6-KH5wH6Houb2dDo4fksBeInI4rg2",
    }
  }
}

const apiCall = ({ url, method }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(mocks[url][method || "GET"]);
      } catch (err) {
        reject(new Error(err));
      }
    }, 1000);
  });

export default apiCall;