<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>ciudades</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="ciudades"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Ciudades</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva Ciudad
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nombre"
                            rules="required|max:30"
                          >
                            <v-text-field
                              v-model="editedItem.nombre"
                              :counter="30"
                              :error-messages="errors"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar la ciudad?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getCiudades">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import cityService from "../../core/http/city.service";
import convertJson from "../../core/utils/convertJson";
import notificationStore from "../../core/utils/notificationStore";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    date: null,
    menu: false,
    menuBaptism: false,
    items: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    ciudades: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
    },
    defaultItem: {
      nombre: "",
    },
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva ciudad" : "Editar ciudad";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
  },

  created() {
    this.getCiudades();
  },

  methods: {
    getCiudades() {
      this.ciudades = [];
      cityService.get().then((response) => {
        this.ciudades = response.data.ciudades;
      });
    },

    editItem(item) {
      this.editedIndex = this.ciudades.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteCiudad(this.editedIndex);
      await this.getCiudades();
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem.nombre = "";
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.ciudades[this.editedIndex], this.editedItem);
        await this.updateCiudad(this.editedItem);
        await this.getCiudades();
      } else {
        await this.addCiudades(this.editedItem);
        await this.getCiudades();
      }
      this.close();
    },

    async updateCiudad(item) {
      const data = convertJson.ConvertToJson(item);

      await cityService.update(item.id, data).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ciudad actualizado correctamente",
          "success"
        );
      });
    },

    async deleteCiudad(cityId) {
      await cityService.delete(cityId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ciudad eliminada correctamente",
          "success"
        );
      });
    },

    async addCiudades(item) {
      const data = convertJson.ConvertToJson(item);

      await cityService.create(data).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ciudad creada correctamente",
          "success"
        );
      });
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
    saveDateBaptism(date) {
      this.$refs.menuBaptism.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
