<template>
  <v-main class="main">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex class="flex" xs12 sm8 md6 lg4 align-self-center>
          <v-img class="image-ppeam" height="200" width="200" src="../../assets/ppeam-logo.jpg" />
          <v-card class="elevation-1 pa-3 card">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-toolbar color="orange darken-4" padless dark flat>
                <v-toolbar-title class="mr-3 header-ppeam"
                  >PPEAM</v-toolbar-title
                >
                <v-divider vertical></v-divider>
                <v-toolbar-title class="ml-3">Iniciar Sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="handleSubmit">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Usuario"
                        rules="required|max:30"
                      >
                        <v-text-field
                          v-model="userName"
                          label="Escriba su nombre de usuario"
                          name="login"
                          prepend-icon="account_box"
                          :error-messages="errors"
                          required
                          @keyup.enter="handleSubmit"
                          type="text"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required|max:30"
                      >
                        <v-text-field
                          v-model="password"
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                          :error-messages="errors"
                          @keyup.enter="handleSubmit"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-layout justify-center>
                      <v-card-actions>
                        <v-btn
                          type="submit"
                          :disabled="invalid"
                          color="primary"
                          >Entrar</v-btn
                        >
                      </v-card-actions>
                    </v-layout>
                  </v-row>
                </v-form>
              </v-card-text>
            </validation-observer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { AUTH_REQUEST } from "../../store/actions/auth.js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

export default {
  name: "Login",
  data() {
    return {
      errors: "",
      userName: "",
      password: "",
    };
  },
  methods: {
    async handleSubmit() {
      const data = {
        user: this.userName,
        password: this.password,
      };

      await this.$store.dispatch(AUTH_REQUEST, data).then((response) => {
        if (response) {
          this.$router.push("/home");
        }
      });
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.header-ppeam {
  font-weight: bold;
}

.main{
  display: '';
}
.flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card{
  width: 100%;
}
</style>
