
import { mapGetters } from 'vuex'

export default {
    data() {
        return {}
    },
    methods: {
        checkUserPermission(action) {
            let isPermitted = false;

            if (!this.permissions) return true;

            for (let permission of this.permissions) {

                if (this.$route.name == permission.path) {

                    if (permission[action]) {
                        isPermitted = true
                        break;
                    } else {
                        break
                    }
                }

            }

            return isPermitted
        }
    },
    computed: {
        ...mapGetters({
            permissions: (state) => state.user.permissions,
        }),
        isReadPermitted() {
            return this.checkUserPermission(null, null, 'read')
        },
        isWritePermitted() {
            return this.checkUserPermission(null, null, 'write')
        },
        isUpdatePermitted() {
            return this.checkUserPermission(null, null, 'update')
        },
        isDeletePermitted() {
            return this.checkUserPermission(null, null, 'delete')
        },
    }
}