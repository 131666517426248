<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search">
              <template v-slot:label>
                Buscar <strong>participantes</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="participantes"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Participantes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo Participante
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Nombre"
                          rules="required|max:10"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            :counter="10"
                            :error-messages="errors"
                            label="Nombre"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Apellido Paterno"
                          rules="required|max:10"
                        >
                          <v-text-field
                            v-model="editedItem.lastNameP"
                            :counter="10"
                            :error-messages="errors"
                            label="Apellido Paterno"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Apellido Materno"
                          rules="required|max:10"
                        >
                          <v-text-field
                            v-model="editedItem.lastNameM"
                            :counter="10"
                            :error-messages="errors"
                            label="Apellido Materno"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Apellido de Casada"
                          rules="max:10"
                        >
                          <v-text-field
                            v-model="editedItem.lastNameC"
                            :counter="10"
                            :error-messages="errors"
                            label="Apellido de Casada"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Teléfono Fijo"
                          rules="required|digits:10"
                        >
                          <v-text-field
                            v-model="editedItem.phone"
                            :counter="10"
                            :error-messages="errors"
                            label="Teléfono Fijo"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Teléfono Celular"
                          rules="required|digits:10"
                        >
                          <v-text-field
                            v-model="editedItem.cellphone"
                            :counter="10"
                            :error-messages="errors"
                            label="Teléfono Celular"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Correo Electrónico"
                          rules="required|max:40|email"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            :counter="40"
                            :error-messages="errors"
                            label="Correo Electrónico"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Circuito"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.circuit"
                            :items="items"
                            :error-messages="errors"
                            label="Circuito"
                            data-vv-name="select"
                            required
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.birth"
                              label="Fecha Nacimiento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="editedItem.birth"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="saveDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          ref="menuBaptism"
                          v-model="menuBaptism"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.baptism"
                              label="Fecha Bautismo"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="pickerBaptism"
                            v-model="editedItem.baptism"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="saveDateBaptism"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          v-model="editedItem.sex"
                          :error-messages="errors"
                          value="1"
                          label="Hombre"
                          type="checkbox"
                          required
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          v-model="editedItem.sex"
                          :error-messages="errors"
                          value="2"
                          label="Mujer"
                          type="checkbox"
                          required
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el participante?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import participantService from "../../core/http/participant.service";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    date: null,
    menu: false,
    menuBaptism: false,
    items: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Apellido Paterno", value: "lastNameP" },
      { text: "Apellido Materno", value: "lastNameM" },
      { text: "Apellido de Casada", value: "lastNameC" },
      { text: "Correo electrónico", value: "email" },
      { text: "Teléfono Fijo", value: "phone" },
      { text: "Teléfono Celular", value: "cellphone" },
      { text: "Congregación", value: "congregation" },
      { text: "Circuito", value: "circuit" },
      { text: "Fecha de Nacimiento", value: "birth" },
      { text: "Fecha de Bautismo", value: "baptism" },
      { text: "Sexo", value: "sex" },
      { text: "Asignación", value: "assignment" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    participantes: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      lastNameP: "",
      lastNameM: "",
      lastNameC: "",
      email: "",
      phone: "",
      cellphone: "",
      congregation: "",
      circuit: "",
      birth: "",
      baptism: "",
      sex: "",
      assignment: "",
    },
    defaultItem: {
      name: "",
      lastNameP: "",
      lastNameM: "",
      lastNameC: "",
      email: "",
      phone: "",
      cellphone: "",
      congregation: "",
      circuit: "",
      birth: "",
      baptism: "",
      sex: "",
      assignment: "",
    },
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Participante"
        : "Editar Participante";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
  },

  created() {
    this.initialize();
    this.getParticipants();
  },

  methods: {
    getParticipants() {
      participantService
        .get()
        .then(() => {});
    },

    initialize() {
      this.participantes = [
        {
          name: "Oswaldo",
          lastNameP: "Osuna",
          lastNameM: "Rangel",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Alan",
          lastNameP: "Osuna",
          lastNameM: "Rangel",
          lastNameC: "",
          email: "alan-d.or@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "13/09/2000",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "Anciano",
        },
        {
          name: "Eliot",
          lastNameP: "Osuna",
          lastNameM: "Rangel",
          lastNameC: "",
          email: "eliot.osuna@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Sahsil",
          lastNameP: "Osuna",
          lastNameM: "Rangel",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "28/12/1991",
          baptism: "02/04/2011",
          sex: "2",
          assignment: "PR",
        },
        {
          name: "Eleodoro",
          lastNameP: "Osuna",
          lastNameM: "Garcia",
          lastNameC: "",
          email: "osunagarcia@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Yolanda",
          lastNameP: "Rangel",
          lastNameM: "Rodríguez",
          lastNameC: "",
          email: "yoli.rrdz@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "2",
          assignment: "PR",
        },
        {
          name: "Priscyla",
          lastNameP: "Muñoz",
          lastNameM: "Pérez",
          lastNameC: "",
          email: "priscymp2008@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "2",
          assignment: "SM/PR",
        },
        {
          name: "Alex",
          lastNameP: "Muñoz",
          lastNameM: "Pérez",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Natán",
          lastNameP: "Castro",
          lastNameM: "",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Ernesto",
          lastNameP: "Briseño",
          lastNameM: "",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
        {
          name: "Pedro",
          lastNameP: "Fernández",
          lastNameM: "Juárez",
          lastNameC: "",
          email: "oswaldoosuna10@gmail.com",
          phone: "8183496624",
          cellphone: "8115377205",
          congregation: "Central, Mty",
          circuit: "Nl-09",
          birth: "05/11/97",
          baptism: "02/04/2011",
          sex: "1",
          assignment: "SM/PR",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.participantes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.participantes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.participantes.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem.name = "";
      this.editedItem.phone = "";
      (this.cellphone = ""), (this.editedItem.email = "");
      (this.editedItem.lastNameM = ""),
        (this.editedItem.lastNameP = ""),
        (this.editedItem.lastNameC = ""),
        (this.editedItem.baptism = ""),
        (this.editedItem.birth = ""),
        (this.editedItem.circuit = null);
      this.editedItem.sex = null;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.participantes[this.editedIndex], this.editedItem);
      } else {
        this.participantes.push(this.editedItem);
      }
      this.close();
    },
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    saveDateBaptism(date) {
      this.$refs.menuBaptism.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
