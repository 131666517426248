<template>
  <v-overlay v-if="dialog">
    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { EventBus } from "./eventhub";

export default {
  name: "Loader",

  data: function() {
    return {
      visible: false,
      dialog: false,
    };
  },

  created() {
    EventBus.$on("toggle-loader", (boolean) => {
      this.dialog = boolean;
    });
  },
};
</script>
