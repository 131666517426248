<template>
  <div>
    <v-alert border="right" colored-border type="error" elevation="3">
      Ha dejado este navegador inactivo. La sesión se cerrara
      en {{ second }} segundos.
    </v-alert>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "../../store/actions/auth";

export default {
  name: "AutoLogout",
  data() {
    return {
      time: 10000,
    };
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;

      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

      if (this.time < 1) {
        clearInterval(timerId);
        this.$store.dispatch(AUTH_LOGOUT);
      }
    }, 1000);
  },
  computed: {
    second() {
      return this.time / 1000;
    },
  },
};
</script>
