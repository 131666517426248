<template id="menuItem" v-slot:activator>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-list-item
          v-if="!menuItem.childrens.length"
          :to="menuItem.path"
          link
          slot="activator"
          v-on="on"
        >
          <v-list-item-icon>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="menuItem.name" class="adjust-text">
          </v-list-item-title>
        </v-list-item>
        <v-list-group
          :v-model="menuItem.active"
          v-else-if="menuItem.childrens.length"
          :key="menuItem.name"
          no-action
          :prepend-icon="menuItem.icon"
          :group="menuItem.group"
        >
          <!--:sub-group="menuItem.subGroup"-->
          <template v-slot:activator>
            <v-list-item-title v-text="menuItem.name"> </v-list-item-title>
          </template>
          <div
            v-for="child in menuItem.childrens"
            :key="child.name"
            class="item-sub-title"
          >
            <tree-menu :menuItem="child"> </tree-menu>
          </div>
        </v-list-group>
      </template>
      <span>{{ menuItem.name }}</span>
    </v-tooltip>
  </div>
</template>

<script type="text/x-template">
export default {
  template: "menuItem",
  props: ["menuItem"],
  name:'tree-menu',
  created() {},
};
</script>
<style>
.v-tooltip__content {
  display: block !important;
  z-index: 10000;
  background: black !important;
  color: white !important;
  border-radius: 16px;
  padding: 5px 10px 4px !important;
  margin-left: -20px;
}
.v-list-item__title {
  white-space: normal !important;
}

/*
.item-sub-title  div {
  margin-top: 7px;
}
*/
</style>
