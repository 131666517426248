<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>usuarios</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="search"
      sort-by="fecha"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="750px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openModalCreate"
              >
                Nuevo Usuario
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <template>
                            <div class="photo">
                              <img
                                v-if="imagePreviewURL"
                                :src="imagePreviewURL"
                                alt=""
                              />
                              <v-file-input
                                ref="fileupload"
                                prepend-icon="mdi-camera"
                                v-model="image"
                                label="Fotografía"
                                @change="onFileChange"
                              >
                              </v-file-input>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Usuario"
                            rules="required"
                          >
                            <v-text-field
                              v-model="editedItem.user"
                              label="Usuario"
                              prepend-icon="mdi-account-circle"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Evento"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="editedItem.email"
                              label="Correo electrónico"
                              prepend-icon="mdi-at"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Contraseña"
                            :rules="!notRequiredRules ? 'required' : ''"
                          >
                            <v-text-field
                              v-model="editedItem.password"
                              label="Contraseña"
                              prepend-icon="mdi-form-textbox-password"
                              :type="showPassword ? 'text' : 'password'"
                              hint="Al menos 8 carácteres"
                              :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :error-messages="errors"
                              @click:append="showPassword = !showPassword"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider v-slot="{ errors }" name="Rol">
                            <v-select
                              prepend-icon="work"
                              v-model="editedItem.id_rol"
                              :items="roles"
                              :error-messages="errors"
                              label="Rol"
                              item-text="nombre"
                              item-value="id"
                              clearable
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Participante"
                          >
                            <v-autocomplete
                              prepend-icon="mdi-account"
                              v-model="editedItem.id_participante"
                              :items="candidatesRef"
                              :error-messages="errors"
                              label="Participante"
                              item-text="name"
                              item-value="id"
                              clearable
                            ></v-autocomplete>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el usuario?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import qs from "qs";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import notificationStore from "../../core/utils/notificationStore";
import ImagePreviewMixin from "../../mixins/ImagePreviewMixin.vue";
import userService from "../../core/http/user.service";
import participantService from "../../core/http/participant.service";
import convertJson from "../../core/utils/convertJson";
import roleService from "../../core/http/rol.service";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    date: null,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    menu: false,
    image: null,
    showPassword: false,
    usuarios: [],
    roles: [],
    participantes: [],
    headers: [
      { text: "Usuario", value: "user" },
      { text: "Correo electrónico", value: "email" },
      { text: "", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: null,
      user: null,
      id_rol: null,
      email: null,
      image: null,
      id_participante: null,
    },
    defaultItem: {
      id: null,
      user: null,
      id_rol: null,
      email: null,
      image: null,
      id_participante: null,
    },
  }),
  mixins: [ImagePreviewMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo usuario" : "Editar usuario";
    },
    candidatesRef() {
      return this.setData(this.participantes, (x) =>
        this.getName(
          x,
          (item) => `${item.n} ${item.ap} ${item.ac === "" ? item.am : item.ac}`
        )
      );
    },
    notRequiredRules() {
      return this.editedIndex != -1;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    async openModalCreate() {
      await this.getRoles();
      await this.getParticipants();
    },
    async editItem(item) {
      await this.openModalCreate();
      await userService.getbyId(item.id).then((response) => {
        this.editedIndex = this.usuarios.indexOf(item);
        this.editedItem = Object.assign({}, response.data.usuario);

        this.dialog = true;
      });
    },
    getName(item, callback) {
      return callback(item);
    },
    customDataProperty(ref_Array, property, callback) {
      for (let index = 0; index < ref_Array.length; index++) {
        const element = ref_Array[index];
        element[property] = callback(element);
      }
    },

    setData(data, callback) {
      let dataRef = [];

      for (let index = 0; index < data.length; index++) {
        dataRef.push({
          id: data[index].id,
          name: callback(data[index]),
          count: data[index].asignados || 0,
        });
      }

      return dataRef;
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      await this.deleteTraining(this.editedIndex);
      await this.getUsers();
    },

    close() {
      this.$refs.observer.reset();
      this.dialog = false;
      this.clear();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.image = null;
      this.$refs.fileupload.$emit("change", null);
      this.$refs.observer.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async getUsers() {
      await userService.get().then((response) => {
        this.usuarios = response.data.usuario;
      });
    },
    async getParticipants() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await participantService.getByCity(json).then((response) => {
        this.participantes = response.data.participant;
      });
      /*
      await axios
        .get(
          `http://iarboledas.info/appeam/dev_appeam/public/api/participant?json={"id_ciudad": ${this.getCity()}}`
        )
        .then((response) => {
          this.participantes = response.data.participant;
        });
        */
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.usuarios[this.editedIndex], this.editedItem);
        await this.updateTraining(this.editedItem, this.close);
        await this.getUsers();
      } else {
        await this.addTraining(this.editedItem, this.close);
        await this.getUsers();
      }
    },

    async updateTraining(item, callback) {
      item.id_ciudad = userService.getCity();
      item.image = this.image != null ? this.image.name : "";
      item.id_usuario_registrador = userService.getUserIdentifier();
      const itemJSON = JSON.stringify(item);
      console.log(itemJSON);
      const itemJSONProtected = qs.stringify({ json: itemJSON });

      await userService.update(item.id, itemJSONProtected).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Capacitación actualizada correctamente",
          "success"
        );
      });
    },

    async getRoles() {
      await roleService.get().then((response) => {
        this.roles = response.data.roles;
      });
      /*
      await axios
        .get("http://iarboledas.info/appeam/dev_appeam/public/api/rol")
        .then((response) => {
          this.roles = response.data.roles;
        });
        */
    },
/*
    async getPartipantes() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      participantService.get

      await axios
        .get(
          `http://iarboledas.info/appeam/dev_appeam/public/api/participant?json={"id_ciudad" : ${this.getCity()}}`
        )
        .then((response) => {
          this.participantes = response.data.participant;
        });
    },
*/
/*-
    getCity() {
      return this.$store.getters.getCity;
    },
    */

    async deleteTraining(shiftId) {
      await userService.delete(shiftId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Capacitación eliminada correctamente",
          "success"
        );
      });
    },

    async addTraining(item, callback) {
      item.id_ciudad = userService.getCity();
      item.image = this.image != null ? this.image.name : "";
      item.id_usuario_registrador = userService.getUserIdentifier();
      const itemJSON = JSON.stringify(item);
      console.log(itemJSON);
      const itemJSONProtected = qs.stringify({ json: itemJSON });

      await userService.create(itemJSONProtected).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Capacitación agregada correctamente",
          "success"
        );
      });
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
<style>
.photo {
  text-align: center;
}

.photo img {
  max-width: 100%;
  max-height: 250px;
  height: 250px;
  width: 250px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
