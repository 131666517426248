const Weekend = {
  Days: Object.freeze({
    Lunes: 1,
    Martes: 2,
    Miercoles: 3,
    Jueves: 4,
    Viernes: 5,
    Sábado: 6,
    Domingo: 7
  }),
  GetDay(numberDay) {
    return Object.keys(this.Days).find(key => this.Days[key] === numberDay) || '';
  },
  GetArrayDays() {
    const array = [];
    const arrayDays =this.Days;

    Object.getOwnPropertyNames(arrayDays).forEach(function (val, index) {
      array.push({ index: index, name: val, value: arrayDays[val] });
    });

    return array;
  },
  GetDayByName(name){
    return this.Days[name];
  }
}

export default Weekend;