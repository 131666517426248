<template>
 <v-container fluid fill-height>
      <v-layout align-center justify-center row>
        <div class="text-md-center">
          <h1 class="errorCode">404</h1>
          <h2 class="my-3 headline ">Oops! Algo salió mal.</h2>
        </div>
      </v-layout>
    </v-container>
</template>

<style>
.d {
  margin: 0 auto;
  width: 25% !important;
}
.errorCode {
  text-align: center;
  vertical-align: middle;
  font-size: 170px;
  line-height: 170px;
  font-weight: 800;
  color: #424242;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
    rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
<script>
export default {
  name: "PageNotFound",
};
</script>
