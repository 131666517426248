<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>participantes</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="participantes"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Participantes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1300px">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" v-appAuthorize="'accion:participante:crear'">
                Nuevo Participante
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nombre"
                            rules="required|max:20"
                          >
                            <v-text-field
                              v-model="editedItem.n"
                              :counter="20"
                              :error-messages="errors"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Apellido Paterno"
                            rules="required|max:20"
                          >
                            <v-text-field
                              v-model="editedItem.ap"
                              :counter="20"
                              :error-messages="errors"
                              label="Apellido Paterno"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Apellido Materno"
                            rules="max:20"
                          >
                            <v-text-field
                              v-model="editedItem.am"
                              :counter="20"
                              :error-messages="errors"
                              label="Apellido Materno"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Apellido de Casada"
                            rules="max:20"
                          >
                            <v-text-field
                              v-model="editedItem.ac"
                              :counter="20"
                              :error-messages="errors"
                              label="Apellido de Casada"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Teléfono Fijo"
                            rules="digits:10"
                          >
                            <v-text-field
                              v-model="editedItem.t"
                              :counter="10"
                              :error-messages="errors"
                              label="Teléfono Fijo"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Teléfono Celular"
                            rules="required|digits:10"
                          >
                            <v-text-field
                              v-model="editedItem.c"
                              :counter="10"
                              :error-messages="errors"
                              label="Teléfono Celular"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Correo Electrónico"
                            rules="required|max:40|email"
                          >
                            <v-text-field
                              v-model="editedItem.e"
                              :counter="40"
                              :error-messages="errors"
                              label="Correo Electrónico"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Circuito"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.circuito"
                              :items="items"
                              :error-messages="errors"
                              label="Circuito"
                              data-vv-name="select"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Congregación"
                            rules="required|max:40"
                          >
                            <v-text-field
                              v-model="editedItem.congregacion"
                              :counter="40"
                              :error-messages="errors"
                              label="Congregación"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.nacimiento"
                                label="Fecha Nacimiento"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="editedItem.nacimiento"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1950-01-01"
                              @change="saveDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-menu
                            ref="menuBaptism"
                            v-model="menuBaptism"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.bautismo"
                                label="Fecha Bautismo"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="pickerBaptism"
                              v-model="editedItem.bautismo"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1950-01-01"
                              @change="saveDateBaptism"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-checkbox
                            v-model="editedItem.sexo"
                            :error-messages="errors"
                            value="M"
                            label="Hombre"
                            type="checkbox"
                            required
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-checkbox
                            v-model="editedItem.sexo"
                            :error-messages="errors"
                            value="F"
                            label="Mujer"
                            type="checkbox"
                            required
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.lun"
                            :items="horariosDisponibles"
                            label="Lunes"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.mar"
                            :items="horariosDisponibles"
                            label="Martes"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.mie"
                            :items="horariosDisponibles"
                            label="Miércoles"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.jue"
                            :items="horariosDisponibles"
                            label="Jueves"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.vie"
                            :items="horariosDisponibles"
                            label="Viernes"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            v-model="editedItem.sab"
                            :items="horariosDisponibles"
                            label="Sábado"
                            multiple
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                          <v-combobox
                            v-model="editedItem.asignacion"
                            :items="asignaciones"
                            label="Asignaciones"
                            multiple
                            chips
                            @change="assignmentChange"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-menu
                            ref="menuFechaRegistro"
                            v-model="menuFechaRegistro"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Fecha Registro"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="editedItem.fecha_registro"
                                  label="Fecha Registro"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </template>
                            <v-date-picker
                              ref="pickerFechaRegistro"
                              v-model="editedItem.fecha_registro"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="2000-01-01"
                              @change="saveDateFechaRegistro"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el participante?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" v-appAuthorize="'accion:participante:editar'">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"  v-appAuthorize="'accion:participante:eliminar'">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getParticipants">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import notificationStore from "../../core/utils/notificationStore";
import participantService from "../../core/http/participant.service";
import circuitService from "../../core/http/circuit.service";
import userService from "../../core/http/user.service";
import convertJson from "../../core/utils/convertJson";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    errors: [],
    date: null,
    assignmentsModel: [
      {
        name: "Precursor Regular",
        value: 1,
        disable: false,
      },
      {
        name: "Precursor Especial",
        value: 10,
        disable: false,
      },
      {
        name: "Misionero",
        value: 100,
        disable: false,
      },
      {
        name: "Siervo Ministerial",
        value: 1000,
        disable: false,
      },
      {
        name: "Anciano",
        value: 10000,
        disable: false,
      },
      {
        name: "S.C.",
        value: 100000,
        disable: false,
      },
      {
        name: "Publicador",
        value: 1,
        disable: false,
      },
    ],
    notAvailables: [11, 11000, 101000],
    asignacionesNumericas: [1, 10, 100, 1000, 10000, 100000, 0],
    asignaciones: [
      "Precursor Regular",
      "Precursor Especial",
      "Misionero",
      "Siervo Ministerial",
      "Anciano",
      "S.C.",
      "Publicador",
    ],
    horariosDisponibles: [
      "Mañana",
      "Tarde",
      "Noche",
      "Medio día",
      "Todo el día",
    ],
    menu: false,
    menuBaptism: false,
    menuFechaRegistro: false,
    items: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "n",
      },
      { text: "Apellido Paterno", value: "ap" },
      { text: "Apellido Materno", value: "am" },
      {
        text: "Apellido de Casada",
        value: "ac",
        width: "10em",
        align: "start",
      },
      { text: "Correo electrónico", value: "e" },
      { text: "Teléfono Fijo", value: "t" },
      { text: "Teléfono Celular", value: "c" },
      { text: "Congregación", value: "congregacion" },
      { text: "Circuito", value: "circuito" },
      { text: "Fecha de Nacimiento", value: "nacimiento" },
      { text: "Fecha de Bautismo", value: "bautismo" },
      { text: "Sexo", value: "sexo" },
      { text: "Asignación", value: "asignacion" },
      { text: "Lunes", value: "lun" },
      { text: "Martes", value: "mar" },
      { text: "Miércoles", value: "mie" },
      { text: "Jueves", value: "jue" },
      { text: "Viernes", value: "vie" },
      { text: "Sábado", value: "sab" },
      { text: "Domingo", value: "dom" },
      { text: "Fecha Registro", value: "fecha_registro" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    participantes: [],
    editedIndex: -1,
    editedItem: {
      n: "",
      ap: "",
      am: "",
      ac: "",
      e: "",
      t: "",
      c: "",
      congregacion: "",
      circuito: "",
      nacimiento: "",
      bautismo: "",
      sexo: "",
      asignacion: "",
      created_at: "",
      dom: "",
      estado: "",
      ciudad: "",
      id_turno: "",
      jue: "",
      lun: "",
      mar: "",
      mie: "",
      foto1: "",
      foto2: "",
      observaciones: "",
      ppeamId: "",
      referencia: "",
      sab: "",
      updated_at: "",
      fecha_registro: "",
      vie: "",
    },
    defaultItem: {
      n: "",
      ap: "",
      am: "",
      ac: "",
      e: "",
      t: "",
      c: "",
      congregacion: "",
      circuito: "",
      nacimiento: "",
      bautismo: "",
      sexo: "",
      asignacion: "",
      created_at: "",
      dom: "",
      estado: "",
      ciudad: "",
      id_turno: "",
      jue: "",
      lun: "",
      mar: "",
      mie: "",
      foto1: "",
      foto2: "",
      observaciones: "",
      ppeamId: "",
      referencia: "",
      sab: "",
      updated_at: "",
      fecha_registro: "",
      vie: "",
    },
    selected: [],
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Participante"
        : "Editar Participante";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
    menuFechaRegistro(val) {
      val &&
        setTimeout(
          () => (this.$refs.pickerFechaRegistro.activePicker = "YEAR")
        );
    },
  },

  created() {
    this.getParticipants();
    this.getCircuitos();
  },

  methods: {
    async getParticipants() {
      this.participantes = [];

      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      participantService.getByCity(json).then((response) => {
        this.participantes = response.data.participant;
        this.participantes.forEach((element, index) => {
          let participantPpeamId = parseInt(element.ppeamId);

          this.participantes[index].asignacion = this.getAsignaciones(
            element.asignacion
          );

          this.participantes[index].lun = this.getDiasDisponibles(element.lun);
          this.participantes[index].mar = this.getDiasDisponibles(element.mar);
          this.participantes[index].mie = this.getDiasDisponibles(element.mie);
          this.participantes[index].jue = this.getDiasDisponibles(element.jue);
          this.participantes[index].vie = this.getDiasDisponibles(element.vie);
          this.participantes[index].sab = this.getDiasDisponibles(element.sab);
          this.participantes[index].dom = this.getDiasDisponibles(element.dom);
          this.nextPpeamId =
            this.nextPpeamId < participantPpeamId
              ? participantPpeamId
              : this.nextPpeamId;
          this.participantes[index].t = this.participantes[index].t.replace(
            /\s/g,
            ""
          );
          this.participantes[index].c = this.participantes[index].c.replace(
            /\s/g,
            ""
          );
        });
      });
    },

    async getCircuitos() {
      circuitService.get().then((response) => {
        response.data.circuitos.forEach((circuito) => {
          this.items.push(circuito.nombre);
        });
      });
    },

    getAsignaciones(asignacionNumerica) {
      let asignacionNumericaString = asignacionNumerica.toString();
      let asignacionesArray = [];
      let asignacionToReturn = "";
      asignacionNumericaString = asignacionNumericaString
        .split("")
        .reverse()
        .join("");
      for (
        let letterPosition = 0;
        letterPosition < asignacionNumericaString.length;
        letterPosition++
      ) {
        if (asignacionNumericaString.charAt(letterPosition) == "1") {
          asignacionesArray.push(this.asignaciones[letterPosition]);
        }
      }
      if (asignacionesArray.length == 0) {
        asignacionToReturn = "Publicador";
      } else {
        for (let i = 0; i < asignacionesArray.length; i++) {
          if (i == 0) {
            asignacionToReturn += asignacionesArray[i];
          } else {
            asignacionToReturn += ", " + asignacionesArray[i];
          }
        }
      }

      return asignacionToReturn;
    },

    getDiasDisponibles(disponibilidadNumerica) {
      let disponibilidadNumericaString = disponibilidadNumerica.toString();
      let diasDisponiblesArray = [];
      let disponibilidadToReturn = "";
      disponibilidadNumericaString = disponibilidadNumericaString
        .split("")
        .reverse()
        .join("");
      for (
        let letterPosition = 0;
        letterPosition < disponibilidadNumericaString.length;
        letterPosition++
      ) {
        if (disponibilidadNumericaString.charAt(letterPosition) == "1") {
          diasDisponiblesArray.push(this.horariosDisponibles[letterPosition]);
        }
      }
      if (diasDisponiblesArray.length == 0) {
        disponibilidadToReturn = "-";
      } else {
        for (let i = 0; i < diasDisponiblesArray.length; i++) {
          if (i == 0) {
            disponibilidadToReturn += diasDisponiblesArray[i];
          } else {
            disponibilidadToReturn += ", " + diasDisponiblesArray[i];
          }
        }
      }

      return disponibilidadToReturn;
    },

    async editItem(item) {
      this.editedIndex = this.participantes.indexOf(item);
      const data = await this.fixDaysToDisplay(item);
      this.editedItem = Object.assign({}, item);
      this.setItem(this.editedItem, data);
      this.dialog = true;
    },

    setItem(item, data) {
      item["asignacion"] = data["asignacion"];
      item["lun"] = data["lun"];
      item["mar"] = data["mar"];
      item["mie"] = data["mie"];
      item["jue"] = data["jue"];
      item["vie"] = data["vie"];
      item["sab"] = data["sab"];
      this.selected = this.editedItem.asignacion;
      this.assignmentChange(this.selected);
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteParticipant(this.editedIndex);
      await this.getParticipants();
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem.n = "";
      this.editedItem.t = "";
      (this.c = ""), (this.editedItem.e = "");
      (this.editedItem.am = ""),
        (this.editedItem.ap = ""),
        (this.editedItem.ac = ""),
        (this.editedItem.bautismo = ""),
        (this.editedItem.nacimiento = ""),
        (this.editedItem.circuito = null);
      this.editedItem.sexo = null;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async fixDaysToDisplay(item) {
      let dayItem = {};
      dayItem.lun = await this.dayToDisplay(item.lun);
      dayItem.mar = await this.dayToDisplay(item.mar);
      dayItem.mie = await this.dayToDisplay(item.mie);
      dayItem.jue = await this.dayToDisplay(item.jue);
      dayItem.vie = await this.dayToDisplay(item.vie);
      dayItem.sab = await this.dayToDisplay(item.sab);
      dayItem.asignacion = await this.dayToDisplay(item.asignacion);
      return dayItem;
    },

    async dayToDisplay(day) {
      day = day.split(", ");
      return day;
    },
    async save() {
      let itemToSend = this.editedItem;
      if (this.editedIndex > -1) {
        Object.assign(this.participantes[this.editedIndex], this.editedItem);
        this.close();
        await this.updateParticipant(itemToSend);
        await this.getParticipants();
      } else {
        this.close();
        await this.addParticipant(itemToSend);
        await this.getParticipants();
      }
    },

    async updateParticipant(item) {
      item = await this.modifyDates(item);
      item.asignacion = await this.modifyAsignaciones(item.asignacion);
      item.congregacion = item.congregacion.toUpperCase();

      const json = convertJson.ConvertToJson(item);

      await participantService.update(item.id, json).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Participante actualizado correctamente",
          "success"
        );
      });
    },

    async deleteParticipant(participantId) {
      await participantService.delete(participantId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Participante eliminado correctamente",
          "success"
        );
      });
    },

    async addParticipant(item) {
      item.id_ciudad = userService.getCity();
      item.ppeamId = "05";
      item = await this.modifyDates(item);
      item.asignacion = await this.modifyAsignaciones(item.asignacion);
      item.congregacion = item.congregacion.toUpperCase();

      const json = convertJson.ConvertToJson(item);

      await participantService.create(json).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Participante eliminado correctamente",
          "success"
        );
      });
    },

    getNumericAssigment(text) {
      const indexOf = this.asignaciones.indexOf(text);
      if (indexOf > -1) {
        return this.asignacionesNumericas[indexOf];
      }

      return 0;
    },
    async modifyAsignaciones(asignaciones) {
      if (!asignaciones) {
        return asignaciones;
      }
      let numericAsignacion = 0;

      if (asignaciones.length == 1) {
        this.asignaciones.forEach((asignacion, index) => {
          if (asignaciones[0] == asignacion) {
            numericAsignacion = this.asignacionesNumericas[index];
          }
        });
      } else {
        for (let turnoDisponible in asignaciones) {
          for (let index = 0; index < this.asignaciones.length; index++) {
            if (asignaciones[turnoDisponible] == this.asignaciones[index]) {
              numericAsignacion += this.asignacionesNumericas[index];
            }
          }
        }
      }
      return numericAsignacion;
    },

    async modifyDates(item) {
      item.lun = await this.modifyDay(item.lun);
      item.mar = await this.modifyDay(item.mar);
      item.mie = await this.modifyDay(item.mie);
      item.jue = await this.modifyDay(item.jue);
      item.vie = await this.modifyDay(item.vie);
      item.sab = await this.modifyDay(item.sab);
      item.dom = await this.modifyDay(item.dom);

      return item;
    },

    async modifyDay(day) {
      if (!day) {
        return day;
      }
      let numericDay = 0;
      if (day.length == 1) {
        this.horariosDisponibles.forEach((asignacion, index) => {
          if (day[0] == asignacion) {
            numericDay = this.asignacionesNumericas[index];
          }
        });
      } else {
        for (let turnoDisponible in day) {
          for (
            let index = 0;
            index < this.horariosDisponibles.length;
            index++
          ) {
            if (day[turnoDisponible] == this.horariosDisponibles[index]) {
              numericDay += this.asignacionesNumericas[index];
            }
          }
        }
      }
      return numericDay;
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
    saveDateBaptism(date) {
      this.$refs.menuBaptism.save(date);
    },
    saveDateFechaRegistro(date) {
      this.$refs.menuFechaRegistro.save(date);
    },
    assignmentChange(actionEvent) {
      const assignments = Object.assign([], this.editedItem.asignacion);
      let item = null;

      if (actionEvent.filter((val) => !this.selected.includes(val)).length) {
        item = actionEvent.filter((v) => !this.selected.includes(v)).toString();

        this.editedItem.asignacion.forEach((assignment) => {
          if (assignment != item) {
            const valueIndex = this.getNumericAssigment(assignment);
            const valueReverse = this.getNumericAssigment(item);
            const sumOfValues = valueIndex + valueReverse;

            if (this.notAvailables.indexOf(sumOfValues) > -1) {
              const index = assignments.indexOf(assignment);
              this.deleteRow(assignments, index);
            }
          }
        });
      } else {
        item = this.selected.filter((v) => !actionEvent.includes(v)).toString();
      }

      this.selected = assignments;
      this.editedItem.asignacion = assignments;
    },
    deleteRow(data, index) {
      if (index > -1) {
        data.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
