<template>
  <v-container fluid>
    <v-row dense>
      <v-col align-self="start">
        <h2>Reporte de total de capacitaciones</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4" md="2" align-self="center">
        <v-btn color="primary" right @click="search()">
          Buscar
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="table-responsive py-5">
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
        no-data-text="No se encontrarón registros"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import userService from "../../../core/http/user.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";
import dataFactory from "../../../core/utils/dataFactory";
import dateUtils from "../../../core/utils/dateUtils";

export default {
  data: () => ({
    date: null,
    months: dateUtils.months,
    years: dateUtils.CreateYears(2020, 2099),
    menu: false,
    pickerDate: null,
    filter: { locationId: "", week: null, date: null, typeId: null },
    headers: [],
    dataheaders: [
      {
        text: "Total Participantes",
        value: "total",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.total || 0;
        },
        show: true,
      },
      {
        text: "Total capacitados teórica",
        value: "teorica",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.teorica || 0;
        },
        show: true,
      },
      {
        text: "Total capacitados campo",
        value: "practica",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.practica || 0;
        },
        show: true,
      },
    ],
    data: [],
    fields: [],
    titles: null,
    locations: [],
    showButtonExport: false,
    showSelectLocation: false,
  }),
  components: {
  },
  computed: {},
  watch: {},
  created() {
    this.search();
  },
  methods: {
    changeDay() {
      this.$refs.picker.save(this.filter.date);
      this.filter.week = dateUtils.GetWeekNumberString(this.filter.date);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
      this.menu = false;
    },
    async search() {
      this.data = [];
      let data = {
        id_ciudad: userService.getCity(),
      };

      let json = qs.stringify({ json: JSON.stringify(data) });

      await reportService.reporte_9(json).then((response) => {
        if (!Array.isArray(response.data.informe)) {
          this.data.push(response.data.informe);
        } else {
          this.data = response.data.informe;
        }

        const dataItems = dataFactory.CreateData(this.dataheaders, this.data);
        this.headers = dataItems.headers;
        this.data = dataItems.data;
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, "value", "text");
      factory.CreateExcel(
        this.data,
        this.fields,
        this.titles,
        "reporte_total_capacitaciones"
      );
    },
    async changeSelected() {
      var item = this.items[this.filter.type];
      this.filter.locationId = "";

      if (item != null) {
        this.showSelectLocation = item.callback();
      }

      if (this.showSelectLocation) {
        await this.getLocations();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
