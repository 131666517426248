<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>experiencias</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="experiencias"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Experiencias</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo Experiencia
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Fecha"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="editedItem.fecha"
                                  label="Fecha"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha"
                              no-title
                              scrollable
                              locale="es-mx"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nombre"
                            rules="required|max:40"
                          >
                            <v-text-field
                              v-model="editedItem.nombre"
                              :counter="40"
                              :error-messages="errors"
                              prepend-icon="mdi-account"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Correo electrónico"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="editedItem.correo"
                              label="Correo electrónico"
                              prepend-icon="mdi-at"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Descripción"
                            rules="required|max:1000"
                          >
                            <v-textarea
                              v-model="editedItem.descripcion"
                              label="Descripción"
                              prepend-icon="mdi-comment-outline"
                              :error-messages="errors"
                              auto-grow
                              clearable
                              solo
                            ></v-textarea>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar la experiencia?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import experienceService from "../../core/http/experience.service";
import convertJson from "../../core/utils/convertJson";
import notificationStore from "../../core/utils/notificationStore";
import userService from "../../core/http/user.service";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    date: null,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    menu: false,
    items: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Nombre", value: "nombre" },
      { text: "", value: "actions", sortable: false },
    ],
    experiencias: [],
    editedIndex: -1,
    editedItem: {
      nombre: null,
      fecha: null,
      descripcion: null,
      correo: null,
      imagen: null,
    },
    defaultItem: {
      nombre: null,
      fecha: null,
      descripcion: null,
      correo: null,
      imagen: null,
    },
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva experiencia"
        : "Editar experiencia";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getExperiences();
  },

  methods: {
    getExperiences() {
      this.experiencias = [];
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);

      experienceService.getByCity(json).then((response) => {
        this.experiencias = response.data.experiencias;
      });
    },
    getCity() {
      return this.$store.getters.getCity;
    },
    editItem(item) {
      this.editedIndex = this.experiencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      await this.deleteExperiencie(this.editedIndex);
      await this.getExperiences();
    },

    close() {
      this.$refs.observer.reset();
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.observer.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.experiencias[this.editedIndex], this.editedItem);
        await this.updateExperience(this.editedItem, () => {
          this.close();
          this.getExperiences();
        });
      } else {
        await this.addExperiencia(this.editedItem, () => {
          this.close();
          this.getExperiences();
        });
      }
    },

    async updateExperience(item, callback) {
      const json = convertJson.ConvertToJson(item);

      await experienceService.update(item.id, json).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Experiencia actualizado correctamente",
          "success"
        );
      });
    },

    async deleteExperiencie(id) {
      await experienceService.delete(id).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Experiencia eliminado correctamente",
          "success"
        );
      });
    },

    async addExperiencia(item, callback) {
      item["id_ciudad"] = userService.getCity();
      item["id_participante"] = userService.getParticipantIdentifier();
      item["imagen"] = null;
      const json = convertJson.ConvertToJson(item);

      await experienceService.create(json).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Experiencia agregado correctamente",
          "success"
        );
      });
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
