<template>
  <v-container v-if="this.$store.getters.isAuthenticated && !['login'].includes($route.name)" class="mt-0">
    <div class="max-w-2xl mx-auto">
      <AutoLogout v-if="isIdle" />
    </div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12 sm12>
          <v-row
            dense
            no-gutters
            justify="end"
            justify-lg="end"
            justify-md="end"
            justify-sm="end"
            align="end"
            align-content-xl="end"
            align-content-md="end"
            align-sm="end"
          >
            <span class="name-user">{{ profile.user }}</span>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            justify-lg="end"
            justify-md="end"
            justify-sm="end"
            align="end"
            align-content-xl="end"
            align-content-md="end"
            align-sm="end"
          >
            <span class="congregation-user">{{ profile.email }}</span>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import AutoLogout from "../auth/autoLogout";

export default {
  name: "info-section",
  components: {
    AutoLogout,
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    ...mapState({
      profile: (state) => state.user.profile,
    }),
  },
};
</script>

<style>
.name-user {
  font-weight: bold;
  font-size: 14px;
}

.congregation-user {
  font-style: italic;
  font-size: 14px;
}
</style>
