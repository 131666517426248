import XLSX from "xlsx";

export default {
    CreateExcel(data, fields, titles, fileName) {
        let dataws = this.CreateWs(data, fields, titles);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, dataws, fileName);
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },
    CreateWs(data, fields, titles) {
        const ws = XLSX.utils.json_to_sheet(
            data,
            {
                header: fields
            }
        )
        const range = XLSX.utils.decode_range(ws['!ref'])

        for (let c = range.s.c; c <= range.e.c; c++) {
            const header = XLSX.utils.encode_col(c) + '1'
            const value = ws[header].v;
            ws[header].v = titles[value]
        }

        return ws
    },
    CreateFields(data, property) {
        var fields = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            fields.push(element[property]);
        }

        return fields;
    },
    CreateTitles(data, property, value) {
        let object = {};
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            object[element[property]] = element[value];
        }

        return object;
    }
}

