<template>
  <v-container fluid>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-row dense>
        <v-col align-self="start">
          <h2>Reporte de capacitaciones</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="Capacitación"
            rules="required"
          >
            <v-select
              :v-slot="{ filter }"
              :items="tipos"
              v-model="filter.kindId"
              item-value="id"
              item-text="value"
              label="Tipo de capacitación"
              :error-messages="errors"
              required
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="4" md="2" align-self="center">
          <v-btn color="primary" right @click="search()" :disabled="invalid">
            Buscar
            <v-icon dark>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <div class="table-responsive py-5">
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
        no-data-text="No se encontrarón registros"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import userService from "../../../core/http/user.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";
import dataFactory from "../../../core/utils/dataFactory";
import dateUtils from "../../../core/utils/dateUtils";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data: () => ({
    date: null,
    months: dateUtils.months,
    years: dateUtils.CreateYears(2020, 2099),
    menu: false,
    pickerDate: null,
    filter: { locationId: "", week: null, date: null, typeId: null },
    headers: [],
    dataheaders: [
      {
        text: "Id participante",
        value: "referencia",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Nombre",
        value: "n",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido Paterno",
        value: "ap",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido Materno",
        value: "am",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido de casada",
        value: "ac",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Email",
        value: "e",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Telefono fijo",
        value: "t",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Celular",
        value: "c",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Circuito",
        value: "circuito",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Congregación",
        value: "congregacion",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Fecha de capacitación",
        value: "fecha_evento",
        align: "center",
        sortable: false,
        callback: null,
        show: true
      },
      {
        text: "Fecha de registro en el sistema",
        value: "fecha_registro",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
    ],
    tipos: [
      { id: 0, value: "Teórico" },
      { id: 1, value: "Práctico o en campo" },
    ],
    data: [],
    fields: [],
    titles: null,
    locations: [],
    showButtonExport: false,
    showSelectLocation: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    changeDay() {
      this.$refs.picker.save(this.filter.date);
      this.filter.week = dateUtils.GetWeekNumberString(this.filter.date);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
      this.menu = false;
    },
    async search() {
      let data = {
        tipo: this.filter.kindId,
        id_ciudad: userService.getCity(),
      };

      let json = qs.stringify({ json: JSON.stringify(data) });

      await reportService.reporte_10(json).then((response) => {
        if (!Array.isArray(response.data.informe)) {
          this.data.push(response.data.informe);
        } else {
          this.data = response.data.informe;
        }

        const dataItems = dataFactory.CreateData(this.dataheaders, this.data);
        this.headers = dataItems.headers;
        this.data = dataItems.data;
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, "value", "text");
      factory.CreateExcel(
        this.data,
        this.fields,
        this.titles,
        "reporte_informes"
      );
    },
    async changeSelected() {
      var item = this.items[this.filter.type];
      this.filter.locationId = "";

      if (item != null) {
        this.showSelectLocation = item.callback();
      }

      if (this.showSelectLocation) {
        await this.getLocations();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
