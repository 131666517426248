//import store from './store'
import Authorization from './router/authorization';

const AppAuthorize = {
    bind: function (el, binding, vnode) {
        CheckValidSection(el, binding, vnode);
    },
}

function CheckValidSection(el, binding, vnode) {

    if (JSON.parse(localStorage.getItem('user-permissions'))) {
        let authorize = "";

        if (Array.isArray(binding.value)) {
            authorize = binding.value.some(x => Authorization.authorize(true, x) == 'authorized') ? 'authorized' : 'notAuthorized';
        } else {
            authorize = Authorization.authorize(true, binding.value);
        }

        if (authorize == 'notAuthorized') {
            vnode.elm.style.display = "none";

            if (vnode.elm.parentElement) {
                vnode.elm.parentElement.removeChild(vnode.elm);
            }
        }
    }
}

export default AppAuthorize;
