import qs from "qs";

export default {
    ConvertToJson(data, property = 'json'){
        const json = {};
        const itemJSON = JSON.stringify(data);
        json[property] = itemJSON;
        const dataJson = qs.stringify(json);

        return dataJson;
    }
}