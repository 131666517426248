<template>
  <v-app v-cloak>
    <Loader />
    <toolbar @toggleNavigationBar="drawer = !drawer" />
    <navigation @isSelected="isSelected" :toggle="drawer" />
    <v-main tag="main">
      <notifications></notifications>
      <info />
      <v-container fluid fill-height>
        <v-layout column wrap fill-height>
          <v-flex md12 d-flex grow>
            <router-view v-if="!this.$store.getters.requireProfile ||  this.$store.getters.isFinishReading" />
          </v-flex>
        </v-layout>
      </v-container>
      <pagefooter />
    </v-main>
  </v-app>
</template>

<script>
import { USER_REQUEST } from "./store/actions/user";
import toolbar from "./components/layout/toolbar.vue";
import navigation from "./components/layout/navigationDrawer";
import pagefooter from "./components/layout/footer";
import info from "./components/layout/infoSecction";
import Loader from "./core/utils/loader.vue";
import Notifications from "./core/utils/notifications.vue";

export default {
  name: "App",

  components: {
    toolbar,
    navigation,
    info,
    pagefooter,
    Loader,
    notifications: Notifications,
  },
  created: async function() {
    if (this.$store.getters.isAuthenticated) {
      const user = JSON.parse(localStorage.getItem("auth-user"));
      this.$store.dispatch(USER_REQUEST, user.id);
    }
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {},
  methods: {
    isSelected(evt) {
      this.drawer = evt;
    },
  },
};
</script>

<style>
.container-scoped {
  position: absolute;
  bottom: 0px;
}

.searchResultsSection {
  height: 100%;
  background: rebeccapurple;
  opacity: 0.6;
}

#app {
  height: 600px;
  width: 100%;
}
</style>
