import Axios from 'axios';
import store from '../../store';

const RESOURCE_NAME = "/user";

export default {
    get() {
        return Axios.get(RESOURCE_NAME);
    },
    getbyId(id) {
        return Axios.get(`${RESOURCE_NAME}/${id}`);
    },
    create(data) {
        return Axios.post(`${RESOURCE_NAME}?${data}`);
    },
    update(id, data) {
        return Axios.put(`${RESOURCE_NAME}/${id}?${data}`);
    },
    delete(id) {
        return Axios.delete(`${RESOURCE_NAME}/${id}`);
    },
    getCity(){
        return store.getters.getCity;
    },
    getRol(){
        return store.getters.getRol;
    },
    getUserIdentifier(){
        return store.getters.getUserIdentifier;
    },
    getParticipantIdentifier(){
        return store.getters.getParticipantId;
    },
    accesacomp(id) {
        return Axios.get(`${RESOURCE_NAME}/accesacomp/${id}`);
    },
    login(data){
        return Axios.post(`${RESOURCE_NAME}/login?${data}`);
    },
    token(){
        return Axios.post(`${RESOURCE_NAME}/token`);
    },
    getData(data){
        return Axios.get(`${RESOURCE_NAME}?${data}`);
    }
}