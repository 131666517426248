<template>
  <v-container fluid>
    <template>
      <v-row dense>
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-select
            :v-slot="{ item }"
            :items="localizations"
            label="Ubicacion"
            v-model="item.ubicacionId"
            item-value="id"
            item-text="nombre"
            @change="changeUbication(item.ubicacionId)"
          >
          </v-select>
        </v-col>
      </v-row>

      <div class="calendar" v-if="calendar.length != 0">
        <v-row>
          <v-sheet tile height="54" class="d-flex">
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title class="title">
              {{ monthName }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-sheet>
        </v-row>
        <ol class="days">
          <li
            class="day"
            v-for="day in calendar"
            v-bind:class="{
              outside: day.outsideOfCurrentMonth,
              empty: day.events.length === 0,
            }"
            :key="day.key"
            @click="changeMount(day.month, day.year)"
          >
            <div class="date">
              <span class="weekday">{{ day.dayOfWeek }}</span>
              <span class="month">{{ day.month }}</span>
              <span class="day">{{ day.number }}</span>
              <span class="year">{{ day.year }}</span>
            </div>
            <div class="events" v-if="day.disabled">
              <div
                v-for="event in day.events"
                class="event"
                v-bind:class="{
                  first: event.firstInRange,
                  last: event.lastInRange,
                  hasData: event.hasKey != null,
                }"
                :key="event.id"

              >
                <span style="width: 80%; float:left"  @click="openModalCreate(day, event)">
                  {{ event.title }}
                </span>

                <span style="width: 10%; float:right">
                    <v-icon small color="red" v-if="event.hasKey != null" @click="deleteItem(event)">delete</v-icon>
                </span>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </template>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" max-width="600px" persistent>
          <v-card>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <form @submit.prevent="submit">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6" sm="12" md="6">
                        <validation-provider name="Turno" rules="required">
                          <v-text-field
                            v-model="editedItem.turnName"
                            label="Turno"
                            disabled
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.fechaFormat"
                          label="Fecha"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Iniciado"
                          rules="required"
                        >
                          <v-select
                            :v-slot="{ editedItem }"
                            :error-messages="errors"
                            :items="select"
                            label="Iniciado"
                            v-model="editedItem.actividad"
                            item-value="value"
                            item-text="text"
                            required
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Biblias"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.biblias"
                            min="0"
                            :error-messages="errors"
                            label="Biblias"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Cursos"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.cursos"
                            min="0"
                            :error-messages="errors"
                            label="Cursos"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Folletos"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.folletos"
                            min="0"
                            :error-messages="errors"
                            label="Folletos"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Libros"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.libros"
                            min="0"
                            :error-messages="errors"
                            label="Libros"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Revistas"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.revistas"
                            min="0"
                            :error-messages="errors"
                            label="Revistas"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Revisitas"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.revisitas"
                            min="0"
                            :error-messages="errors"
                            label="Revisitas"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Tarjetas de Contacto"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.tarjetas"
                            min="0"
                            :error-messages="errors"
                            label="Tarjetas de Contacto"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Tratados"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.tratados"
                            min="0"
                            :error-messages="errors"
                            label="Tratados"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Videos"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.videos"
                            min="0"
                            :error-messages="errors"
                            label="Videos"
                            type="number"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Semana"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.semana"
                            min="0"
                            :error-messages="errors"
                            label="Semana"
                            type="text"
                            disabled
                            flat
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <validation-provider name="Turno">
                          <v-select
                            :v-slot="{ editedItem }"
                            :items="candidatesRef"
                            label="Ausencias"
                            v-model="absences"
                            item-value="id"
                            item-text="name"
                            multiple
                            small-chips
                            clearable
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Observaciones"
                          rules="max:50"
                        >
                          <v-text-field
                            v-model="editedItem.observaciones"
                            :counter="50"
                            :error-messages="errors"
                            label="Observaciones"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  :disabled="invalid"
                  text
                  @click="save"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px" persistent>
              <v-card>
                <v-card-title class="headline"
                  >¿Borrar el regitro?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
      </div>
    </template>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import reportService from "../../core/http/report.service";
import shiftService from "../../core/http/turn.service";
import userService from "../../core/http/user.service";
import dateUtils from "../../core/utils/dateUtils";
import notificationStore from "../../core/utils/notificationStore";
import absenceService from "../../core/http/absences.service";
import convertJson from "../../core/utils/convertJson";
import locationService from "../../core/http/location.service";
import turnService from "../../core/http/turn.service";
import turnUtils from "../../core/utils/turnUtils";
import Guid from "../../core/utils/uuidv4";
import reportInfoService from "../../core/http/report-info.service";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  props: {
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },

  data: () => ({
    monthChage: 0,
    yearChange: 0,
    item: {
      ubicacionId: null,
    },
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    daysOfWeek: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    calendar: [],
    value: "",
    localizations: [],
    dialog: false,
    dialogDelete: false,
    items: [],
    events: [],
    select: [
      { value: 1, text: "Si" },
      { value: 0, text: "No" },
    ],
    reportes: [],
    editedIndex: -1,
    editedItem: {
      turn: null,
      fecha: null,
      actividad: true,
      libros: 0,
      revistas: 0,
      folletos: 0,
      biblias: 0,
      videos: 0,
      revisitas: 0,
      cursos: 0,
      tratados: 0,
      tarjetas: 0,
      observaciones: null,
      semana: 0,
      id_turno: null,
      id_user: null,
      absences: [],
      turnName: null
    },
    defaultItem: {
      fecha: null,
      actividad: 0,
      libros: 0,
      revistas: 0,
      folletos: 0,
      videos: 0,
      revisitas: 0,
      biblias: 0,
      cursos: 0,
      tratados: 0,
      tarjetas: 0,
      observaciones: null,
      semana: 0,
      id_turno: null,
      id_user: null,
      absences: [],
      turnName: null
    },
    turns: [],
    absences: [],
    ubication: null,
    itemDelete: null,
    absencesData: []
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo informe" : "Editar informe";
    },
    candidatesRef() {
      return this.setData(
        this.assigneds,
        (x) => this.getName(x),
        (x) => x.id_participante
      );
    },
    monthName() {
      return `${this.months[this.monthChage - 1]} ${this.yearChange}`;
    },
  },
  mounted() {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    this.monthChage = this.month;
    this.yearChange = this.year;
    await this.getLocalization();
  },
  ready() {},
  methods: {
    changeMount(month, year) {
      console.log(month, year)
      console.log(this.monthChage)
      if (month != this.monthChage) {
        this.monthChage = month;
        this.yearChange = year;
        this.updateDays();
      }
    },
    updateDays() {
      this.calendar = [];

      let daysInMonth = this.daysInMonth(this.monthChage, this.yearChange);
      let firstDay = new Date(this.yearChange, this.monthChage - 1, 1);

      let previousMonthDays = firstDay.getDay();

      let offset = 0 - previousMonthDays;
      let lastDay = new Date(this.yearChange, this.monthChage - 1 + 1, 0);
      let nextMonthDays = offset + (6 - lastDay.getDay());
      let total = daysInMonth + previousMonthDays + nextMonthDays;

      for (let i = offset; i < total; i++) {
        let current = this.addDays(firstDay, i);

        this.calendar.push({
          key: Guid.uuidv4(), //`${current.getDate()}${current.getMonth()}${current.getFullYear()}`,
          outsideOfCurrentMonth: i < 0 || i > daysInMonth - 1 ? true : false,
          dayOfWeek: this.daysOfWeek[current.getDay()],
          unix: this.daysOfWeek[current.getDay()],
          number: current.getDate(),
          month: current.getMonth() + 1,
          year: current.getFullYear(),
          date: current,
          disabled: current.getMonth() + 1 == this.monthChage,
          events: this.turns
            .filter((f) => f.dia == current.getDay())
            .map((m) => {
              return {
                id: Guid.uuidv4(),
                title: turnUtils.getTurnHour(m, "hora_inicio", "hora_fin"),
                hasKey: this.reportes.find(
                  (r) =>
                    r.id_turno == m.id &&
                    dateUtils.GetFormatDate(current) == r.fecha
                ),
                turn: m.id,
                place: m.ubicacion,
              };
            }),
        });
      }
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    async prev() {
      this.monthChage = this.monthChage - 1;
      if (this.monthChage == 0) {
        this.monthChage = 12;
        this.yearChange = this.yearChange - 1;
      }
      await this.getReports()
      //await this.changeMount(this.monthChage, this.yearChange)
      this.updateDays();
    },
    async next() {
      this.monthChage = this.monthChage + 1;
      if (this.monthChage == 13) {
        this.monthChage = 1;
        this.yearChange = this.yearChange + 1;
      }
      await this.getReports()
      //await this.changeMount(this.monthChage, this.yearChange)
      this.updateDays();
    },
    setData(data, callback, callbackId) {
      let dataRef = [];

      for (let index = 0; index < data.length; index++) {
        dataRef.push({
          id: callbackId(data[index]),
          name: callback(data[index]),
          count: data[index].asignados || 0,
        });
      }

      return dataRef;
    },
    changeDataFromReport() {
      let turn = this.turns.find((f) => f.id == this.editedItem.id_turno);
      if (turn) {
        this.editedItem.turn = turnUtils.getTurnHour(
          turn,
          "hora_inicio",
          "hora_fin"
        );
      }
      let dateSplit = dateUtils.GetDate(this.editedItem.fecha, "-");
      let newDate = new Date(
        dateSplit.getFullYear,
        dateSplit.getMonth - 1,
        dateSplit.getDay
      );
      this.editedItem.fechaFormat = dateUtils.GetFormat(newDate);
    },
    deleteItem(item) {
      this.itemDelete = item.hasKey.id
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.getPinf(this.itemDelete);

      this.absencesData.forEach(async (x) => {
        await this.deleteAbsence(x.id);
      });

      await this.deletereport(this.itemDelete, this.closeDelete);
      await this.changeUbication(this.ubication.id);
    },
    close() {
      this.$refs.observer.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.selected = [];
      this.dialog = false;
    },
    clear() {
      this.absences = [];
      this.absencesData = [];
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    getName(item) {
      return `${item.n} ${item.ap} ${item.ac === "" ? item.am : item.ac}`;
    },
    async getTurn(ubication_id) {
      var data = {
        id_ciudad: userService.getCity(),
        id_ubicacion: ubication_id,
      };
      const json = convertJson.ConvertToJson(data);

      await turnService.getByCity(json).then((response) => {
        this.turns = response.data.turnos;
      });
    },
    async changeUbication(ubication_id) {
      this.ubication = this.localizations.find((f) => f.id == ubication_id);
      await this.getTurn(ubication_id);
      await this.getReports();
      this.updateDays();
    },
    async getLocalization() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await locationService.getByCity(json).then((response) => {
        this.localizations = response.data.ubicacions;
      });
    },
    async getAssigned(shift_id) {
      await shiftService.getAssigned(shift_id).then((response) => {
        this.assigneds = response.data.asignados;
      });
    },
    async getTurns() {
      var data = userService.getCity();
      await shiftService.getShiftOfc(data).then((response) => {
        this.turns = response.data.turnos;
      });
    },
    async getReports() {
      this.reportes = [];
      const data = {
        ubicacion: this.ubication.nombre,
        año: this.yearChange,
        mes: this.monthChage,
        id_ciudad: userService.getCity(),
      };

      const json = convertJson.ConvertToJson(data);
      await reportInfoService.reporte_6(json).then((response) => {
        this.reportes = response.data.informe;
      });
    },
    async openModalCreate(calendar, event) {
      this.clear();
      await this.getAssigned(event.turn);
      if (event.hasKey) {
        await this.editItem(event.hasKey.id);
      } else {
        let dateFormatString = dateUtils.GetFormatDate(calendar.date);
        this.editedItem.turnName = event.title;
        this.editedItem.turn = event.turn;
        this.editedItem.id_turno = event.turn
        this.editedItem.fechaFormat = dateUtils.GetFormat(calendar.date);
        this.editedItem.fecha = dateUtils.GetFormatDate(calendar.date);
        this.editedItem.semana =
          dateUtils.GetWeekNumberString(dateFormatString);
      }

      this.dialog = true;
    },
    async editItem(id) {
      await reportService.getbyId(id).then(async (response) => {
        this.editedItem = Object.assign({}, response.data.informe);
        this.changeDataFromReport();
        await this.getPinf(id);
      });
    },
    async save() {
      if (this.editedItem.id != null) {
        await this.updateReporte(this.editedItem);
      } else {
        await this.addReportes(this.editedItem);
      }

      await this.changeUbication(this.ubication.id);
      this.close();
    },
    async updateReporte(item) {
      const json = convertJson.ConvertToJson(item);

      await reportService.update(item.id, json).then(() => {
        this.updateAbsences(item.id);
        notificationStore.showNotification(
          "Operación exitosa",
          "Informe actualizado correctamente",
          "success"
        );
      });
    },
    async deletereport(reportId, callback) {
      await reportService.delete(reportId).then(() => {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Informe eliminado correctamente",
          "success"
        );
      });
    },
    async addReportes(item) {
      item["id_user"] = userService.getUserIdentifier();
      const json = convertJson.ConvertToJson(item);

      await reportService.create(json).then((response) => {
        const reportId = response.data.informe.id;
        this.updateAbsences(reportId);

        notificationStore.showNotification(
          "Operación exitosa",
          "Informe agregado correctamente",
          "success"
        );
      });
    },
    async saveAbsences(item) {
      const json = convertJson.ConvertToJson(item);
      await absenceService.create(json).then(() => {});
    },
    async getPinf(report_id) {
      await absenceService.pinf(report_id).then((response) => {
        const absences = response.data.ausencias;
        this.absencesData = absences;
        this.absences = absences.map((x) => x.id_participante);
      });
    },
    async deleteAbsence(id) {
      await absenceService.delete(id).then(() => {});
    },
    async updateAbsences(report_id) {
      const newAbsences = this.absences.filter(
        (x) => !this.selected.some((r) => r.id_participante == x)
      );
      const deleteAbsences = this.selected.filter(
        (x) => !this.absences.some((r) => r == x.id_participante)
      );

      newAbsences.forEach(async (competitor_id) => {
        const item = {
          id_informe: report_id,
          id_participante: competitor_id,
        };
        await this.saveAbsences(item);
      });

      deleteAbsences.forEach(async (x) => {
        await this.deleteAbsence(x.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$base: #555;
$primary: #222;
$accent: #0c0c0c;
$hasKey: #0d8002;
.calendar {
  & > .title {
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 2rem;
    color: $primary;
    & > * {
      display: inline;
    }
  }
  .days {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 650px;
    // & > .day.outside { display: none; }
    //& > .day.empty { display: none; }
    .events {
      margin-bottom: 1rem;
      .event {
        box-sizing: border-box;
        line-height: 1;
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: rgba($accent, 0.05);
        color: $accent;
        padding: 0.25rem 0.5rem;
        margin-bottom: 2px;

        cursor: pointer;
        transition: all 0.1s ease-in-out;
        &:hover,
        &:focus {
          background: rgba($accent, 0.1);
        }
        &:active {
          color: white;
          background: rgba($accent, 1);
        }

        &.first {
          border-left: 1px solid rgba($accent, 0.5);
        }
        &.last {
          border-right: 1px solid rgba($accent, 0.5);
        }

        &.hasData {
          background: rgba($hasKey, 0.5);
        }
      }
    }
    .date {
      position: relative;
      font-size: 1.25rem;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: currentColor;
        opacity: 0.5;
      }
      & > * {
        display: inline-block;
      }
      .month{
        display: none
      }
      .weekday {
        font-weight: 400;
        color: $primary;
        text-transform: uppercase;
      }
      .weekday:after,
      .day:after {
        content: "-";
      }
    }
  }
}
@media (min-width: 1024px) {
  .calendar {
    //border-bottom: 2px solid $accent;
    .days {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;

      & > .day {
        position: relative;
        font-size: 0.75rem;
        margin-bottom: 0;
        padding: 0 0 7% 0;
        width: 100/7 * 1%;
        flex-shrink: 0;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0.5rem;
          top: 0;
          height: 1px;
          background: currentColor;
          opacity: 0.5;
        }
        .date {
          position: absolute;
          top: 1rem;
          font-size: 1rem;
          line-height: 1rem;
          &:after {
            display: none;
          }
          .weekday,
          .month,
          .year {
            display: none;
          }
          .day:hover {
            cursor: pointer;
          }
          .day:after {
            content: "";
          }
        }

        &.outside {
          display: inline-block;
          //background: rgba(0,0,0,.125);
          &:before {
            opacity: 0.125;
          }
          .date .day {
            opacity: 0.5;
          }
        }
        &.empty {
          display: inline-block;
        }
        &:nth-child(n + 1):nth-child(-n + 7) {
          margin-top: 2rem;
          &:before {
            opacity: 1;
            height: 2px;
            background: $accent;
          }
          .date {
            width: 100%;
          }
          .date .weekday {
            display: block;
            position: absolute;
            top: -3rem;
            width: 100%;
            overflow: hidden;
            text-transform: uppercase;
            font-weight: 300;
            color: $primary;
            text-overflow: ellipsis;
            &:after {
              content: "";
            }
          }
        }
        .events {
          box-sizing: border-box;
          position: absolute;
          height: 100%;
          width: 100%;
          padding-top: 2.5rem;
          padding-right: 0.5rem;
          overflow: auto;
          .event {
            box-sizing: border-box;
            line-height: 1;
            font-size: 0.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: rgba($accent, 0.05);
            color: $accent;
            padding: 0.25rem 0.5rem;
            margin-bottom: 2px;

            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover,
            &:focus {
              background: rgba($accent, 0.1);
            }
            &:active {
              color: white;
              background: rgba($accent, 1);
            }

            &.first {
              border-left: 1px solid rgba($accent, 0.5);
            }
            &.last {
              border-right: 1px solid rgba($accent, 0.5);
            }

            &.hasData {
              background: rgba($hasKey, 0.5);
            }
          }
        }
      }
    }
  }
}
</style>
