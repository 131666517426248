<template>
  <v-navigation-drawer
    v-model="isSelected"
    fixed
    app
    v-if="
      this.$store.getters.isAuthenticated && this.$store.getters.isFinishReading
    "
  >
    <v-toolbar flat dark class="toolbar" dense>
      <router-link :to="{ name: 'Home' }" class="text">
        PPEAM
      </router-link>
    </v-toolbar>
    <v-list>
      <!--
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-list-item to="/home" v-on="on">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
        </template>
        <span>Inicio</span>
      </v-tooltip>
    -->
      <div class="tree">
        <div v-for="(menuItem, i) in menu" :key="i">
          <tree-menu :menuItem="menuItem"></tree-menu>
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import treemenu from "../../core/component/drawerMenuComponent.vue";

export default {
  props: {
    toggle: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedIndex: 1,
      isSelected: this.toggle,
    };
  },
  methods: {},
  computed: mapState({
    menu: (state) => state.user.menu,
  }),
  watch: {
    toggle: function(newValue) {
      this.isSelected = newValue;
    },
    isSelected: function(newValue) {
      const vm = this;
      vm.$emit("isSelected", newValue);
    },
  },
  components: {
    "tree-menu": treemenu,
  },
};
</script>

<style>
.toolbar {
  font-weight: bold;
  font-size: 18px;
}

.toolbar .text {
  padding-left: 15px;
  color: white;
  text-decoration: none;
}

.item-title {
  font-size: 17px;
  font-weight: 500;
}

.item-sub-title {
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
}

.active {
  font-weight: bold;
}

.v-list-item__icon{
  margin-right: 5px !important;
}
</style>
