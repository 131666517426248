<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>capacitaciones</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="trainings"
      :search="search"
      sort-by="fecha"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Capacitaciones</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="750px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openModalCreate"
              >
                Nuevo Capacitación
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Participante"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.id_participante"
                              :items="candidatesRef"
                              :error-messages="errors"
                              label="Participante"
                              multiple
                              item-text="name"
                              item-value="id"
                              chips
                              clearable
                              small-chips
                              deletable-chips
                              :disabled="editedIndex != -1"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Evento"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.id_evento"
                              :items="eventos"
                              :error-messages="errors"
                              label="Evento"
                              item-text="descripcion"
                              item-value="id"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Fecha"
                              >
                                <v-text-field
                                  clearable
                                  v-model="editedItem.fecha"
                                  readonly
                                  label="Fecha"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha"
                              no-title
                              scrollable
                              locale="es-mx"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar la capacitación?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import notificationStore from "../../core/utils/notificationStore";
import trainingService from "../../core/http/training.service";
import userService from "../../core/http/user.service";
import participantService from "../../core/http/participant.service";
import eventService from "../../core/http/event.service";
import convertJson from "../../core/utils/convertJson";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    date: null,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    menu: false,
    items: [],
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Particpante", value: "name" },
      { text: "Evento", value: "descripcion" },
      { text: "Fecha de Evento", value: "fecha_evento" },
      { text: "", value: "actions", sortable: false },
    ],
    eventos: [],
    participantes: [],
    editedIndex: -1,
    editedItem: {
      fecha: null,
      id_evento: null,
      id_participante: null,
    },
    defaultItem: {
      fecha: null,
      id_evento: null,
      id_participante: null,
    },
    trainings: [],
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva capacitación"
        : "Editar capacitación";
    },
    candidatesRef() {
      return this.setData(this.participantes, (x) =>
        this.getName(
          x,
          (item) => `${item.n} ${item.ap} ${item.ac === "" ? item.am : item.ac}`
        )
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.getTrainings();
  },
  methods: {
    async openModalCreate() {
      await this.getEvents();
      await this.getParticipants();
    },
    getCity() {
      return this.$store.getters.getCity;
    },
    async editItem(item) {
      await this.openModalCreate();
      await trainingService.getbyId(item.id).then((response) => {
        this.editedIndex = this.trainings.indexOf(item);
        this.editedItem = Object.assign({}, response.data.capacitaciones);

        this.dialog = true;
      });
    },
    getName(item, callback) {
      return callback(item);
    },
    customDataProperty(ref_Array, property, callback) {
      for (let index = 0; index < ref_Array.length; index++) {
        const element = ref_Array[index];
        element[property] = callback(element);
      }
    },

    setData(data, callback) {
      let dataRef = [];

      for (let index = 0; index < data.length; index++) {
        dataRef.push({
          id: data[index].id,
          name: callback(data[index]),
          count: data[index].asignados || 0,
        });
      }

      return dataRef;
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      await this.deleteTraining(this.editedIndex);
      await this.getTrainings();
    },

    close() {
      this.$refs.observer.reset();
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.observer.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getParticipants() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await participantService.getByCity(json).then((response) => {
        this.participantes = response.data.participant;
      });
    },
    async getEvents() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await eventService.getByCity(json).then((response) => {
        this.eventos = response.data.eventos;
      });
    },
    async getTrainings() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);

      await trainingService.getByJson(json).then((response) => {
        this.trainings = response.data.capacitaciones;
        this.customDataProperty(this.trainings, "name", (x) =>
          this.getName(
            x,
            (item) =>
              `${item.participante_n} ${item.participante_ap} ${item.participante_am}`
          )
        );
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.eventos[this.editedIndex], this.editedItem);
        await this.updateTraining(this.editedItem, this.close);
        await this.getTrainings();
      } else {
        await this.addTraining(this.editedItem, this.close);
        await this.getTrainings();
      }
    },

    async updateTraining(item, callback) {
      const itemJSONProtected = convertJson.ConvertToJson(item);

      await trainingService.update(item.id, itemJSONProtected).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Capacitación actualizada correctamente",
          "success"
        );
      });
    },

    async deleteTraining(shiftId) {
      await trainingService.delete(shiftId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Capacitación eliminada correctamente",
          "success"
        );
      });
    },

    async addTraining(item, callback) {
      for (let index = 0; index < item.id_participante.length; index++) {
        const element = item.id_participante[index];
        const training = {
          id_evento: item.id_evento,
          id_participante: element,
          fecha: item.fecha,
        };
        const itemJSONProtected = convertJson.ConvertToJson(training);

        await trainingService.create(itemJSONProtected).then(function() {
          callback();
          notificationStore.showNotification(
            "Operación exitosa",
            "Capacitación agregada correctamente",
            "success"
          );
        });
      }
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
