<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label> Buscar <strong>roles</strong> </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="roles"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Roles</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px" @input="getComponents()">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo Rol
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nombre del rol"
                            rules="required|max:50"
                          >
                            <v-text-field
                              v-model="item.nombre"
                              :counter="50"
                              :error-messages="errors"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Referencia"
                            rules="required|max:20"
                          >
                            <v-text-field
                              v-model="item.ref"
                              :counter="20"
                              :error-messages="errors"
                              label="Referencia"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-card>
                            <v-card-title>
                              Permisos
                              <v-spacer></v-spacer>
                              <v-text-field
                                v-model="searchPermissions"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-card-title>
                            <v-data-table
                              v-model="selected"
                              :headers="headersPermision"
                              :items="components"
                              sort-by="nombre"
                              class="elevation-1"
                              :single-select="singleSelect"
                              show-select
                              :search="searchPermissions"
                            ></v-data-table>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el rol?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getRoles">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import rolService from "../../core/http/rol.service";
import componentService from "../../core/http/component.service";
import convertJson from "../../core/utils/convertJson";
import userService from "../../core/http/user.service";
import accessService from "../../core/http/access.service";
import notificationStore from "../../core/utils/notificationStore";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    searchPermissions: "",
    date: null,
    menu: false,
    menuBaptism: false,
    components: [],
    singleSelect: false,
    selected: [],
    headersPermision: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Componente", value: "ref" },
    ],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Referencia", value: "ref" },
      { text: "", value: "actions", sortable: false },
    ],
    permissionsInRol: [],
    roles: [],
    editedIndex: -1,
    item: {
      nombre: "",
      ref: "",
    },
    defaultItem: {
      Nombre: "",
      Referencia: "",
    },
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo rol" : "Editar rol";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
  },

  created() {
    this.getRoles();
  },

  methods: {
    async getRoles() {
      this.roles = [];
      await rolService.get().then((response) => {
        this.roles = response.data.roles;
      });
    },
    async getComponents() {
      this.components = [];
      this.permissionsInRol = [];
      this.selected = [];
      await componentService.get().then((response) => {
        this.components = response.data.componentes;
      });
    },

    async getComponentsByRol(id_rol) {
      await userService.accesacomp(id_rol).then((response) => {
        this.permissionsInRol = response.data.accesos;
        this.permissionsInRol.map((x) => {
          this.selected.push({
            id: x.id,
            nombre: x.nombre,
            ref: x.ref,
            id_accesos: x.id_accesos,
          });
        });
      });
    },

    savePermissions() {
      const newPermissions = this.selected.filter(
        (x) => !this.permissionsInRol.some((r) => r.ref == x.ref)
      );

      const updatePermissions = this.permissionsInRol.filter((x) =>
        this.selected.some((r) => r.ref == x.ref)
      );
      const deletePermissions = this.permissionsInRol.filter(
        (x) => !this.selected.some((r) => r.ref == x.ref)
      );

      newPermissions.forEach(async (x) => {
        const data = {
          ref_rol: this.item.ref,
          ref_componente: x.ref,
          status: 1,
        };

        const json = convertJson.ConvertToJson(data);

        await accessService.create(json).then(() => {});
      });

      updatePermissions.forEach(async (x) => {
        const data = {
          ref_rol: this.item.ref,
          ref_componente: x.ref,
          status: 1,
        };

        const json = convertJson.ConvertToJson(data);

        await accessService.update(x.id_accesos, json).then(() => {});
      });

      deletePermissions.forEach(async (x) => {
        await accessService.delete(x.id_accesos).then(() => {});
      });
    },
    editItem(item) {
      this.editedIndex = this.roles.indexOf(item);
      this.item = Object.assign({}, item);
      this.getComponents();
      this.getComponentsByRol(this.item.id, this.selected);
      this.dialog = true;
    },
    deleteItem(item) {
      this.item = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.deleteRol(this.item);
      await this.getRoles();
      this.closeDelete();
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.permissionsInRol = [];
      this.selected = [];
      this.searchPermissions = null;
      this.item = Object.assign({}, { id: null, nombre: "", ref: "" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteRol(item) {
      return await rolService.delete(item.id);
    },
    async save() {
      const rol = {
        nombre: this.item.nombre,
        ref: this.item.ref,
      };
      const json = convertJson.ConvertToJson(rol);

      if (this.editedIndex > -1) {
        await this.updateRol(this.item.id, json).then(() => {
          this.savePermissions();
          this.close();
          this.getRoles();
          notificationStore.showNotification(
            "Operación exitosa",
            "Turno actualizado correctamente",
            "success"
          );
        });
      } else {
        this.addRoles(json).then(() => {
          this.savePermissions();
          this.close();
          this.getRoles();
          notificationStore.showNotification(
            "Operación exitosa",
            "Turno actualizado correctamente",
            "success"
          );
        });
      }
    },
    async updateRol(id, item) {
      await rolService.update(id, item);
    },
    async addRoles(item) {
      await rolService.create(item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
