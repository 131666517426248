const NotificationStore = {
    state: [],

    addNotification: function (notification) {
        this.state.push(notification)
    },
    removeNotification: function (notification) {
        const index = this.state.indexOf(notification);

        if (index !== -1) {
            this.state.splice(index, 1);
        }
    },
    showNotification(title, text, type) {
        const notification = {
            title: title,
            text: text,
            type: type,
            timeout: true,
            position: "bottom",
        };

        this.addNotification(notification);
    },
    count(){
        return this.state.length;
    }

}

export default NotificationStore;