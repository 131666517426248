<template>
  <v-container>
    <div v-for="alert in reminders" :key="alert.id" class="container">
      <v-alert
        :v-model="alert"
        dismissible
        elevation="2"
        class="center"
        color="#26c6da"
        rounded="md"
        max-width="1050"
        dark
        dense
      >
        <h4>{{ alert.titulo }}</h4>
        {{ alert.mensaje }}
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import reminderService from "../../core/http/reminder.service";
import userService from "../../core/http/user.service";
import convertJson from "../../core/utils/convertJson";

export default {
  data: () => ({
    reminders: [],
  }),
  async created() {
    //await this.getRemiders();
  },
  methods: {
    async getRemiders() {
      this.eventos = [];
      const data = {
        id_ciudad: await userService.getCity(),
        id_rol: await userService.getRol(),
        id_usuario: await userService.getUserIdentifier(),
      };
      console.log(data);
      const json = convertJson.ConvertToJson(data);
      console.log(json);

      await reminderService.get(json).then((response) => {
        console.log(response);
        this.reminders = response.data.recordatorios;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-family: arial;
  font-size: 24px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
</style>
