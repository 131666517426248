<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label>
                Buscar <strong>ubicaciones</strong>
              </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="ubicaciones"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Ubicaciones</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openModalNew()"
              >
                Nueva Ubicación
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nombre"
                            rules="required|max:30"
                          >
                            <v-text-field
                              v-model="editedItem.nombre"
                              :counter="30"
                              :error-messages="errors"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Ciudad"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.id_ciudad"
                              :items="cities"
                              :error-messages="errors"
                              label="Ciudad"
                              item-value="id"
                              item-text="nombre"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el ubicación?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getUbicaciones">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import cityService from "../../core/http/city.service";
import locationService from "../../core/http/location.service";
import notificationStore from "../../core/utils/notificationStore";
import userService from '../../core/http/user.service';
import convertJson from "../../core/utils/convertJson";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    date: null,
    menu: false,
    menuBaptism: false,
    items: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Ciudad", value: "ciudad" },
      { text: "", value: "actions", sortable: false },
    ],
    ubicaciones: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
    },
    defaultItem: {
      nombre: "",
    },
    cities: [],
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva ubicación" : "Editar ubicación";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
  },

  created() {
    this.getUbicaciones();
  },

  methods: {
    getUbicaciones() {
      this.ubicaciones = [];
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      locationService.getByCity(json).then((response) => {
        this.ubicaciones = response.data.ubicacions;
      });
    },
    async getCities() {
      await cityService.get().then((response) => {
        this.cities = response.data.ciudades;
      });
    },
    async editItem(item) {
      await this.getCities();
      this.editedIndex = this.ubicaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async openModalNew() {
      await this.getCities();
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteLocation(this.editedIndex);
      await this.getUbicaciones();
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clear() {
      this.editedItem.n = "";
      this.editedItem.t = "";
      (this.c = ""), (this.editedItem.e = "");
      (this.editedItem.am = ""),
        (this.editedItem.ap = ""),
        (this.editedItem.ac = ""),
        (this.editedItem.bautismo = ""),
        (this.editedItem.nacimiento = ""),
        (this.editedItem.circuito = null);
      this.editedItem.sexo = null;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.ubicaciones[this.editedIndex], this.editedItem);
        await this.updateUbicación(this.editedItem);
        await this.getUbicaciones();
      } else {
        await this.addUbicaciones(this.editedItem);
        await this.getUbicaciones();
      }
      this.close();
    },

    async updateUbicación(item) {
      const json = convertJson.ConvertToJson(item);

      await locationService.update(item.id, json).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ubicación actualizada correctamente",
          "success"
        );
      });
    },

    async deleteLocation(locationId) {
      await locationService.delete(locationId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ubicación eliminada correctamente",
          "success"
        );
      });
    },

    async addUbicaciones(item) {
      const json = convertJson.ConvertToJson(item);
      await locationService.create(json).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Ubicación creada correctamente",
          "success"
        );
      });
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
    saveDateBaptism(date) {
      this.$refs.menuBaptism.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
