import S3 from "aws-sdk/clients/s3";
import AWS from 'aws-sdk'

export default {
    async getSecret() {
        var client = new AWS.SecretsManager({
            region: 'us-east-1'
        });

        await client.getSecretValue({
            SecretId: 'ppeam/prod/secrets'
        }, function (err, data) {
            if (err) throw err
            if ('SecretString' in data) {
                console.log(data.SecretString);
            } else {
                let buff = new Buffer(data.SecretBinary, 'base64');
                console.log(buff.toString('ascii'));
            }
        });
    },

    configureS3() {
        return new S3();
    },
    uploadImaged(payload) {
        let s3 = new S3();

        let encodedImage = payload.file;
        let decodedImage = Buffer.from(encodedImage, 'base64');
        var filePath = payload.fileName;

        const params = {
            "Body": decodedImage,
            "Bucket": "bucket-app-ppeam-images",
            "Key": filePath,
            "ACL": "public-read",
            "ContentType": payload.contentType
        }

        return new Promise(function (resolve, reject) {
            s3.upload(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });

    }

}