<template>
  <v-container fluid>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-row dense>
        <v-col align-self="start">
          <h2>Reporte de informes</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="ubicacion"
            rules="required"
          >
            <v-select
              :v-slot="{ filter }"
              :items="locations"
              v-model="filter.locationId"
              item-value="nombre"
              item-text="nombre"
              label="Ubicación"
              :error-messages="errors"
              required
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="picker"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Fecha"
                rules="required"
              >
                <v-text-field
                  v-model="filter.date"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="filter.date"
              no-title
              scrollable
              locale="es-mx"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="changeDay(date)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="tiporeporte"
            rules="required"
          >
            <v-select
              :v-slot="{ filter }"
              :items="reportType"
              v-model="filter.typeId"
              item-value="id"
              item-text="value"
              label="Tipo Reporte"
              :error-messages="errors"
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="4" md="2" align-self="center">
          <v-btn color="primary" right @click="search()" :disabled="invalid">
            Buscar
            <v-icon dark>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <div class="table-responsive py-5">
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
        no-data-text="No se encontrarón registros"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import userService from "../../../core/http/user.service";
import locationService from "../../../core/http/location.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";
import dataFactory from "../../../core/utils/dataFactory";
import dateUtils from "../../../core/utils/dateUtils";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data: () => ({
    date: null,
    months: dateUtils.months,
    years: dateUtils.CreateYears(2020, 2099),
    menu: false,
    pickerDate: null,
    filter: { locationId: "", week: null, date: null, typeId: null },
    headers: [],
    dataheaders: [
      {
        text: "Total Actividad",
        value: "actividad",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.actividad || 0;
        },
        show: true,
      },
      {
        text: "Total Libros",
        value: "libros",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.libros || 0;
        },
        show: true,
      },
      {
        text: "Total Revistas",
        value: "revistas",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.revistas || 0;
        },
        show: true,
      },
      {
        text: "Total Folletos",
        value: "folletos",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.folletos || 0;
        },
        show: true,
      },
      {
        text: "Total Videos",
        value: "videos",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.videos || 0;
        },
        show: true,
      },
      {
        text: "Total Revisitas",
        value: "revisitas",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.revisitas || 0;
        },
        show: true,
      },
      {
        text: "Total Cursos",
        value: "cursos",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.cursos || 0;
        },
        show: true,
      },
      {
        text: "Total Tratados",
        value: "tratados",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.tratados || 0;
        },
        show: true,
      },
      {
        text: "Total Tarjetas",
        value: "tarjetas",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.tarjetas || 0;
        },
        show: true,
      },
      {
        text: "Total Biblias",
        value: "biblias",
        align: "center",
        sortable: false,
        callback: (element) => {
          return element.biblias || 0;
        },
        show: true,
      },
    ],
    reportType: [
      {
        id: 1,
        value: "Año",
      },
      {
        id: 2,
        value: "Mes",
      },
      {
        id: 3,
        value: "Semana",
      },
    ],
    data: [],
    fields: [],
    titles: null,
    locations: [],
    showButtonExport: false,
    showSelectLocation: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {},
  watch: {},
  created() {
    this.getLocations();
  },
  methods: {
    changeDay() {
      this.$refs.picker.save(this.filter.date);
      this.filter.week = dateUtils.GetWeekNumberString(this.filter.date);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
      this.menu = false;
    },
    async getLocations() {
      let data = {
        id_ciudad: userService.getCity(),
      };
      let json = qs.stringify({ json: JSON.stringify(data) });
      await locationService.getByCity(json).then((response) => {
        this.locations = response.data.ubicacions;
      });
    },
    async search() {
      const dateSelected = dateUtils.GetDate(this.filter.date, "-");
      let year = dateSelected.getFullYear;
      let month = dateSelected.getMonth;
      let week = this.filter.week || "";

      if (this.filter.typeId == 1) {
        month = "";
        week = "";
      } else if (this.filter.typeId == 2) {
        week = "";
      }

      let data = {
        ubicacion: this.filter.locationId,
        mes: month,
        año: year,
        semana: week,
        id_ciudad: userService.getCity(),
      };

      let json = qs.stringify({ json: JSON.stringify(data) });

      await reportService.reporte_7(json).then((response) => {
        if (!Array.isArray(response.data.informe)) {
          this.data.push(response.data.informe);
        } else {
          this.data = response.data.informe;
        }

        const dataItems = dataFactory.CreateData(this.dataheaders, this.data);
        this.headers = dataItems.headers;
        this.data = dataItems.data;
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, "value", "text");
      factory.CreateExcel(this.data, this.fields, this.titles, "reporte_informes");
    },
    async changeSelected() {
      var item = this.items[this.filter.type];
      this.filter.locationId = "";

      if (item != null) {
        this.showSelectLocation = item.callback();
      }

      if (this.showSelectLocation) {
        await this.getLocations();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
