<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col cols="6">
        <v-form>
          <v-container>
            <v-text-field v-model="search" @keypress.enter.prevent>
              <template v-slot:label> Buscar <strong>turnos</strong> </template>
            </v-text-field>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="turnos"
      :search="search"
      sort-by="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Turnos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="getData()"
              >
                Nuevo Turno
              </v-btn>
            </template>
            <v-card>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <form @submit.prevent="submit">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="capacidad"
                            rules="required|min_value:0"
                          >
                            <v-text-field
                              v-model="editedItem.capacidad"
                              :error-messages="errors"
                              label="Capacidad"
                              type="number"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Horario"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.id_horario"
                              :items="turnsRef"
                              :error-messages="errors"
                              label="Horario"
                              item-text="name"
                              item-value="id"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Ubicación"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.id_ubicacion"
                              :items="localizations"
                              :error-messages="errors"
                              label="Ubicación"
                              item-text="nombre"
                              item-value="id"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Día"
                            rules="required"
                          >
                            <v-select
                              v-model="editedItem.dia"
                              :items="daysOfWeek"
                              :error-messages="errors"
                              label="Día"
                              item-value="value"
                              item-text="name"
                              required
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clear">
                    Limpiar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="invalid"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Quiere borrar el turno?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getTurnos">
          Buscar
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  required,
  digits,
  email,
  max,
  regex,
  min_value,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import weeked from "../../core/utils/daysEnum";
import turnUtils from "../../core/utils/turnUtils";
import notificationStore from "../../core/utils/notificationStore";
import turnService from "../../core/http/turn.service";
import convertJson from "../../core/utils/convertJson";
import userService from "../../core/http/user.service";
import locationService from "../../core/http/location.service";
import scheduleService from "../../core/http/schedule.service";

setInteractionMode("eager");

extend("min_value", {
  ...min_value,
  message: "{_field_} tiene que ser mayor a {min}",
});

extend("digits", {
  ...digits,
  message: "{_field_} tiene que contener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

extend("max", {
  ...max,
  message: "{_field_} no debe ser mayor a {length} caracteres",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} no coincide {regex}",
});

extend("email", {
  ...email,
  message: "correo electrónico debe ser válido",
});

export default {
  data: () => ({
    dialog: false,
    nextPpeamId: 0,
    dialogDelete: false,
    search: "",
    date: null,
    items: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Capacidad", value: "capacidad" },
      { text: "Dia", value: "nameOfDay" },
      { text: "Horario", value: "nameOfTurn" },
      { text: "Ubicación", value: "ubicacion" },
      { text: "", value: "actions", sortable: false },
    ],
    turnos: [],
    editedIndex: -1,
    editedItem: {
      capacidad: "",
      dia: "",
      id_horario: "",
      id_ubicacion: "",
    },
    defaultItem: {
      capacidad: null,
      dia: null,
      id_horario: null,
      id_ubicacion: null,
    },
    daysOfWeek: [],
    localizations: [],
    hours: [],
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo turno" : "Editar turno";
    },
    turnsRef() {
      return this.setData(
        this.hours,
        (x) => this.getTurnHour(x),
        "id_horarios"
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menuBaptism(val) {
      val && setTimeout(() => (this.$refs.pickerBaptism.activePicker = "YEAR"));
    },
  },

  async created() {
    this.getTurnos();
  },
  methods: {
    async getData() {
      this.daysOfWeek = weeked.GetArrayDays();
      await this.getLocalization();
      await this.getHours();
    },
    setData(data, callback, property_id) {
      let dataRef = [];

      for (let index = 0; index < data.length; index++) {
        dataRef.push({
          id: data[index][property_id || "id"],
          name: callback(data[index]),
        });
      }

      return dataRef;
    },
    async getHours() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await scheduleService.getByCity(json).then((response) => {
        this.hours = response.data.horarios;
        turnUtils.order(this.hours, "id_ciudad", "hora_inicio", "hora_fin");
      });
    },
    getTurnHour(item) {
      return turnUtils.getTurnHour(item, "hora_inicio", "hora_fin");
    },
    getCity() {
      return this.$store.getters.getCity;
    },
    async getLocalization() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await locationService.getByCity(json).then((response) => {
        this.localizations = response.data.ubicacions;
      });
    },
    async getTurnos() {
      this.turnos = [];
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await turnService.getByCity(json).then((response) => {
        this.makeview(response.data.turnos, this.turnos);
      });
    },
    makeview(array, referenceArray) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        element["nameOfDay"] = weeked.GetDay(element.dia);
        element["nameOfTurn"] = turnUtils.getTurnHour(
          element,
          "hora_inicio",
          "hora_fin"
        );
        referenceArray.push(element);
      }

      console.log(referenceArray)
      referenceArray = referenceArray.sort(function(a, b) {
    return a.id - b.id;
})
    },
    async editItem(item) {
      await turnService.getById(item.id).then((response) => {
        this.editedIndex = this.turnos.indexOf(item);
        this.editedItem = Object.assign({}, response.data.turno);

        this.dialog = true;
      });
      await this.getData();
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await this.deleteTurno(this.editedIndex);
      await this.getTurnos();
    },
    close() {
      this.$refs.observer.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.observer.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.turnos[this.editedIndex], this.editedItem);
        await this.updateTurno(this.editedItem, this.close);
        await this.getTurnos();
      } else {
        await this.addTurnos(this.editedItem, this.close);
        await this.getTurnos();
      }
    },
    async updateTurno(item, callback) {
      const itemJSONProtected = convertJson.ConvertToJson(item);

      await turnService.update(item.id, itemJSONProtected).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Turno actualizado correctamente",
          "success"
        );
      });
    },
    async deleteTurno(shiftId) {
      await turnService.delete(shiftId).then(function() {
        notificationStore.showNotification(
          "Operación exitosa",
          "Turno eliminado correctamente",
          "success"
        );
      });
    },
    async addTurnos(item, callback) {
      item["id_ciudad"] = userService.getCity();
      const itemJSONProtected = convertJson.ConvertToJson(item);
      await turnService.create(itemJSONProtected).then(function() {
        callback();
        notificationStore.showNotification(
          "Operación exitosa",
          "Turno guardado correctamente",
          "success"
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
