const schema = {
    sections: [
        {
            name: 'Inicio',
            description: '',
            path: '/home',
            icon: 'home',
            reference: 'menu:recordatorios',
            is_group: false,
            requireAuthentication: false,
            isToolbar: false
        },
        {
            name: 'Formularios',
            description: '',
            path: '/Formularios',
            icon: 'view_module',
            reference: 'menu:formularios',
            is_group: true,
            requireAuthentication: true,
            isToolbar: true,
            childrens: [
              /*
                {
                    name: 'Crear Recordatorios',
                    description: 'Vea un listado de los recordatorios. Agregue, edite o elimine recordatorios.',
                    path: '/Recordatorios',
                    icon: 'mail',
                    reference: 'menu:recordatorios',
                    is_group: false,
                    color: "#485A40",
                    requireAuthentication: false,
                },
                */
                {
                    name: 'Participantes',
                    description: 'Vea un listado de los participantes. Agregue, edite o elimine participantes.',
                    path: '/Participantes',
                    icon: 'people',
                    reference: 'formulario:participantes',
                    is_group: false,
                    color: "#385F73",
                    requireAuthentication: true,
                }, {
                    name: 'Asignaciones',
                    description: 'Vea un listado de las asignaciones. Agregue, edite o elimine asignaciones.',
                    path: '/Asignaciones',
                    icon: 'date_range',
                    reference: 'formulario:asignaciones',
                    is_group: false,
                    color: "#685F73",
                    requireAuthentication: true,
                }, {
                    name: 'Ciudades',
                    description: 'Vea un listado de las ciudades. Agregue, edite o elimine ciudades.',
                    path: '/Ciudades',
                    icon: 'apartment',
                    reference: 'formulario:ciudades',
                    is_group: false,
                    color: "#385F50",
                    requireAuthentication: true,
                }, {
                    name: 'Circuitos',
                    description: 'Vea un listado de los circuitos. Agregue, edite o elimine circuitos.',
                    path: '/Circuitos',
                    icon: 'horizontal_distribute',
                    reference: 'formulario:circuitos',
                    is_group: false,
                    color: "#485A40",
                    requireAuthentication: true,
                }, {
                    name: 'Roles de Seguridad',
                    description: 'Vea un listado de los roles de seguridad. Agregue, edite o elimine un rol de seguridad.',
                    path: '/Roles',
                    icon: 'shield',
                    reference: 'formulario:roles',
                    is_group: false,
                    color: "#685F73",
                    requireAuthentication: true,
                }, {
                    name: 'Horarios',
                    description: 'Vea un listado de los horarios. Agregue, edite o elimine horarios.',
                    path: '/Horarios',
                    icon: 'schedule',
                    reference: 'formulario:horarios',
                    is_group: false,
                    color: "#183F73",
                    requireAuthentication: true,
                }, {
                    name: 'Ubicaciones',
                    description: 'Vea un listado de las ubicaciones. Agregue, edite o elimine ubicaciones.',
                    path: '/Ubicaciones',
                    icon: 'location_on',
                    reference: 'formulario:ubicaciones',
                    is_group: false,
                    color: "#985F73",
                    requireAuthentication: true,
                }, {
                    name: 'Turnos',
                    description: 'Vea un listado de los turnos. Agregue, edite o elimine turnos.',
                    path: '/Turnos',
                    icon: 'history',
                    reference: 'formulario:turnos',
                    is_group: false,
                    color: "#385F73",
                    requireAuthentication: true,
                }, {
                    name: 'Eventos',
                    description: 'Vea un listado de los eventos. Agregue, edite o elimine eventos.',
                    path: '/Eventos',
                    icon: 'event',
                    reference: 'formulario:eventos',
                    is_group: false,
                    color: "#485A40",
                    requireAuthentication: true,
                }, {
                    name: 'Capacitaciones',
                    description: 'Vea un listado de las capacitaciones. Agregue, edite o elimine capacitaciones.',
                    path: '/Capacitaciones',
                    icon: 'border_color',
                    reference: 'formulario:capacitaciones',
                    is_group: false,
                    color: "#685F73",
                    requireAuthentication: true,
                }, {
                    name: 'Informes',
                    description: 'Vea un listado de los informes. Agregue, edite o elimine informes.',
                    path: '/Informes',
                    icon: 'summarize',
                    reference: 'formulario:informes',
                    is_group: false,
                    color: "#183F73",
                    requireAuthentication: true,
                },/* {
            name: 'Ausencias',
            description: 'Vea un listado de las ausencias. Agregue, edite o elimine ausencias.',
            path: '/Ausencias',
            icon: 'person_add_disabled',
            reference: 'formulario:ausencias',
            is_group: false,
            color: "#985F73",
            visible: false
        }, */
                {
                    name: 'Experiencias',
                    description: 'Vea un listado de las experiencias. Agregue, edite o elimine experiencias.',
                    path: '/Experiencias',
                    icon: 'announcement',
                    reference: 'formulario:experiencias',
                    is_group: false,
                    color: "#385F50",
                    requireAuthentication: true,
                },
                {
                    name: 'Usuarios',
                    description: 'Vea un listado de los usuarios. Agregue, edite o elimine usuarios.',
                    path: '/Usuarios',
                    icon: 'group_add',
                    reference: 'formulario:usuarios',
                    is_group: false,
                    color: "#385F73",
                    requireAuthentication: true,
            }]
        }, {
            name: 'Reportes',
            description: '',
            path: '/Reportes',
            icon: 'assignment',
            reference: 'menu:reportes',
            is_group: true,
            requireAuthentication: true,
            childrens: [
                {
                    name: 'Participantes',
                    description: 'Vea un listado de los totales de participantes',
                    path: '/Reporte_participantes',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:totales:particpantes',
                    is_group: false,
                    color: "#385F73",
                    requireAuthentication: true,
                },
                {
                    name: 'Participantes Asignados',
                    description: 'Vea un listado de los participantes asignados y sin asignar',
                    path: '/Reporte_asignados',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:particpantes:asignados',
                    is_group: false,
                    color: "#685F73",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte de ausencias',
                    description: 'Vea un listado de los participantes con ausencias',
                    path: '/Reporte_ausencias',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:participantes:ausencias',
                    is_group: false,
                    color: "#085A6C",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte informes de servicio',
                    description: 'Vea un listado de los totales de informes',
                    path: '/Reporte_informes',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:informes:servicio',
                    is_group: false,
                    color: "#08540F",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte totales de capacitaciones',
                    description: 'Vea un listado de los totales de capacitaciones',
                    path: '/Reporte_totales_capacitaciones',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:informes:totalescapacitaciones',
                    is_group: false,
                    color: "#485A40",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte capacitaciones',
                    description: 'Vea un listado de las capacitaciones',
                    path: '/Reporte_capacitaciones',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:informes:capacitaciones',
                    is_group: false,
                    color: "#9B2C14",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte participantes por punto',
                    description: 'Vea un listado de participantes por punto',
                    path: '/Reporte_participantes_punto',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:informes:capitanes',
                    is_group: false,
                    color: "#085A6C",
                    requireAuthentication: true,
                },
                {
                    name: 'Reporte disponibilidad por turno',
                    description: 'Vea un listado de disponibilidad por turno',
                    path: '/Reporte_disponibilidad_turnos',
                    icon: 'mdi-clipboard-account',
                    reference: 'reporte:informes:turnos',
                    is_group: false,
                    color: "#28CAC0",
                    requireAuthentication: true,
                }
            ],
            isToolbar: true
        },
        /*
        {
            name: 'Configuraciones',
            description: '',
            path: '/Configuraciones',
            icon: 'settings',
            reference: 'menu:configuraciones',
            is_group: true,
            requireAuthentication: true,
            childrens:[
                {
                    name: 'Crear Recordatorios',
                    description: 'Vea un listado de los recordatorios. Agregue, edite o elimine recordatorios.',
                    path: '/recordatorios',
                    icon: 'mail',
                    reference: 'menu:recordatorios',
                    is_group: false,
                    color: "#485A40",
                    requireAuthentication: false,
                }
            ]
        }
        */
    ]
}

export default {
    makeMenu() {
        const permissions = JSON.parse(localStorage.getItem('user-permissions'));
        const menu = this.createSection(schema.sections, permissions, 0);

        return menu;
    },
    makeIndex(nameSection) {
        const permissions = JSON.parse(localStorage.getItem('user-permissions'));
        const schema_Forms = schema.sections.filter(x => x.name == nameSection);
        const menu = this.createSection(schema_Forms, permissions, 0)[0] || null;

        return menu?.childrens || null;
    },
    findChildren(permissions, childrens) {
        let show = false;
        for (let index = 0; index < permissions.length; index++) {
            show = childrens.some(x => x.reference == permissions[index].ref);
            if (show) {
                break;
            }
        }

        return show;
    },
    createSection(schema, permissions, depth) {
        let menu = [];

        for (let index = 0; index < schema.length; index++) {
            const item = schema[index];
            let access = true;

            if (item.requireAuthentication) {
                access = permissions.filter(x => x.ref == item.reference)[0] || false;
            }

            if (item.is_group && item.requireAuthentication) {
                access = this.findChildren(permissions, item.childrens);
            }

            if (access) {
                let section = {
                    name: item.name,
                    path: item.path,
                    icon: item.icon,
                    isToolbar: item.isToolbar,
                    description: item.description,
                    authorize: item.reference,
                    childrens: item.childrens || [],
                    visible: true,
                    group: item.name,
                    subGroup: item.is_group && depth > 0,
                    color: item.color || null,
                    active: false,
                };

                if (item.is_group) {
                    depth = depth + 1;
                    section.childrens = this.createSection(item.childrens, permissions, depth);

                    if (!section.childrens.length) {
                        section.visible = false;
                    }
                }

                menu.push(section);
            }
        }

        depth = 0;

        return menu;
    }
}