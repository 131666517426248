<template>
  <v-container fluid>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-row dense>
        <v-col align-self="start">
          <h2>Reporte disponibilidad por turno</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="ubicacion"
            rules="required"
          >
            <v-select
              :v-slot="{ filter }"
              :items="locations"
              v-model="filter.locationId"
              item-value="nombre"
              item-text="nombre"
              label="Ubicación"
              :error-messages="errors"
              required
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="4" md="2" align-self="center">
          <v-btn color="primary" right @click="search()" :disabled="invalid">
            Buscar
            <v-icon dark>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <div class="table-responsive py-5">
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
        no-data-text="No se encontrarón registros"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import userService from "../../../core/http/user.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";
import dataFactory from "../../../core/utils/dataFactory";
import dateUtils from "../../../core/utils/dateUtils";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dayFactory from "../../../core/utils/daysEnum";
import hourFactory from "../../../core/utils/turnUtils";
import locationService from "../../../core/http/location.service";

export default {
  data: () => ({
    date: null,
    months: dateUtils.months,
    years: dateUtils.CreateYears(2020, 2099),
    menu: false,
    pickerDate: null,
    filter: { locationId: "" },
    headers: [],
    dataheaders: [
      {
        text: "Dia",
        value: "dia",
        sortable: false,
        align: "center",
        callback: (element) => {
          return dayFactory.GetDay(element.dia);
        },
        show: true,
      },
      {
        text: "Hora inicio",
        value: "hora_inicio",
        sortable: false,
        align: "center",
        callback: (element) => {
          return hourFactory.convertHour(element.hora_inicio);
        },
        show: true,
      },
      {
        text: "Hora fin",
        value: "hora_fin",
        sortable: false,
        align: "center",
        callback: (element) => {
          return hourFactory.convertHour(element.hora_fin);
        },
        show: true,
      },
      {
        text: "Capacidad",
        value: "capacidad",
        sortable: false,
        align: "center",
        callback: null,
        show: true,
      },
      {
        text: "Participantes",
        value: "participantes",
        sortable: false,
        align: "center",
        callback: null,
        show: true,
      },
    ],
    data: [],
    fields: [],
    titles: null,
    locations: [],
    showButtonExport: false,
    showSelectLocation: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {},
  watch: {},
  async created() {
    await this.getLocations();
  },
  methods: {
    changeDay() {
      this.$refs.picker.save(this.filter.date);
      this.filter.week = dateUtils.GetWeekNumberString(this.filter.date);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
      this.menu = false;
    },
    async search() {
      let data = {
        ubicacion: this.filter.locationId,
        id_ciudad: userService.getCity(),
      };

      let json = qs.stringify({ json: JSON.stringify(data) });

      await reportService.reporte_13(json).then((response) => {
        if (!Array.isArray(response.data.participant)) {
          this.data.push(response.data.participant);
        } else {
          this.data = response.data.participant;
        }

        const dataItems = dataFactory.CreateData(this.dataheaders, this.data);
        this.headers = dataItems.headers;
        this.data = dataItems.data;
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, "value", "text");
      factory.CreateExcel(
        this.data,
        this.fields,
        this.titles,
        "reporte_informes"
      );
    },
    async changeSelected() {
      var item = this.items[this.filter.type];
      this.filter.locationId = "";

      if (item != null) {
        this.showSelectLocation = item.callback();
      }

      if (this.showSelectLocation) {
        await this.getLocations();
      }
    },
    async getLocations() {
      let data = {
        id_ciudad: userService.getCity(),
      };
      let json = qs.stringify({ json: JSON.stringify(data) });
      await locationService.getByCity(json).then((response) => {
        this.locations = response.data.ubicacions;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
