const Turn = {
  getTurnHour(item, property_one, property_two) {
    return `${this.convertHour(item[property_one])} - ${this.convertHour(
      item[property_two]
    )}`;
  },
  convertHour(hour) {
    const index = hour.toString().length - 2;
    const min = hour.toString().substr(index, hour.toString().length);
    const hr = hour.toString().substr(0, index);

    return `${hr.padStart(2,'0')}:${min.padStart(2,'0')}`;
  },
  order(array, property_one, property_two) {
    array.sort(function (a, b) {
      if (a[property_one] === b[property_one]) {
        return b[property_two] - a[property_two];
      }

      return 1 ;
    });

    array.reverse();
  }
}

export default Turn;