var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"flat":"","dark":"","app":"","dense":""}},[(
      this.$store.getters.isAuthenticated &&
        this.$store.getters.isFinishReading
    )?_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleNavigationBar}}):_vm._e(),(
      this.$store.getters.isAuthenticated &&
        this.$store.getters.isFinishReading
    )?_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.menu),function(menuItem,i){return [(menuItem.visible && menuItem.isToolbar)?_c('v-btn',{directives:[{name:"appAuthorize",rawName:"v-appAuthorize",value:(menuItem.authorize),expression:"menuItem.authorize"}],key:i,attrs:{"to":menuItem.path,"text":""}},[_vm._v(_vm._s(menuItem.name)+" ")]):_vm._e()]})],2):_vm._e(),_c('v-spacer'),(
      !_vm.$route.name == '/' && !this.$store.getters.isAuthenticated
    )?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleLogin}},[_c('v-icon',[_vm._v("account_circle")]),_c('span',{staticClass:"mr-2"},[_vm._v("Iniciar Sesión")])],1):_vm._e(),[(this.$store.getters.isAuthenticated)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_c('span',{staticClass:"mr-2"},[_vm._v("CERRAR SESIÓN")]),_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Desea cerrar la sesión? ")]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleLogout}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }