export default {

    CreateData(dataHeaders, data) {
        let headers = [];
        let dataSource = [];


        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let item = {};

            for (var propertyName in element) {
                let header = dataHeaders.find(x => x.value == propertyName);

                if (header != undefined && header != null) {
                    const indexOf = headers.findIndex(x => x.value == header.value);

                    if (indexOf == -1) {
                        headers.push({
                            text: header.text,
                            value: header.value,
                            align: header.align,
                            sortable: header.sortable,
                        });
                    }

                    item[header.value] = header.callback != null ? header.callback(element) : data[index][propertyName];
                }
            }

            dataSource.push(item);
        }
        return {
            headers: headers,
            data: dataSource,
        }
    }
};