<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col xl="8" sm="12" md="12">
        <v-row>
          <v-col xl="6" sm="12" md="12">
            <v-card outlined>
              <v-card-title class="title">
                <v-icon x-large left color="white">
                  account_circle
                </v-icon>
                Datos de contacto<br />
                {{ username }}<v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="pb-10">
                <v-row>
                  <v-col cols="6">
                    <v-subheader
                      class="grey--text text--lighten-1 pl-0 subheader"
                      >Email</v-subheader
                    >
                    <label>{{ email }}</label>
                  </v-col>
                  <v-col cols="6">
                    <v-subheader
                      class="grey--text text--lighten-1 pl-0 subheader"
                      >Referencia</v-subheader
                    >
                    <label>{{ referencia }}</label>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xl="6" sm="12" md="12">
            <v-card outlined>
              <v-card-title class="title">
                <v-icon x-large left color="white">
                  home
                </v-icon>
                Congregación<br />
                {{ congregacion }}<v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="pb-10">
                <v-row>
                  <v-col cols="6">
                    <v-subheader
                      class="grey--text text--lighten-1 pl-0 subheader"
                      >Bautismo</v-subheader
                    >
                    <label>{{ bautismo }}</label>
                  </v-col>
                  <v-col cols="6">
                    <v-subheader
                      class="grey--text text--lighten-1 pl-0 subheader"
                      >Asignación</v-subheader
                    >
                    <label class="badge">{{ asignacion }}</label>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="text-h5 text--primary">
              Fotos de perfil
            </p>
            <div class="wrapper">
              <v-row>
                <v-col xl="6" sm="12" md="6">
                  <p class="text--primary bold">
                    Fotografía tipo pasaporte
                  </p>
                  <span>
                    Esta será la principal foto de perfil que servirá para que
                    el cuerpo de ancianos, la sucursal y otras personas con
                    acceso a ella lo puedan identificar.
                  </span>
                  <v-col cols="12">
                    <template>
                      <div class="box">
                        <img
                          class="js--image-preview"
                          :src="passaport.photo"
                          v-if="passaport"
                        />
                      </div>
                      <div class="buttons upload-options">
                        <label class="custom-file-upload text-color">
                          <v-icon dense color="blue">border_color</v-icon>
                          <input
                            ref="fileuploadPassaport"
                            type="file"
                            class="image-upload"
                            accept="image/*"
                            @change="getPhotoPassaport"
                          />
                          Editar
                        </label>
                        <label
                          class="custom-file-upload text-color"
                          @click="clearPhotoPassaport"
                        >
                          <v-icon dense color="blue">delete</v-icon>
                          Borrar
                        </label>
                      </div>
                    </template>
                  </v-col>
                </v-col>
                <v-col xl="6" sm="12" md="6">
                  <p class="text--primary bold">
                    Fotografía de cuerpo entero
                  </p>
                  <span>
                    En las solicitudes para algunas facetas de servicio se pide
                    una fotografía de cuerpo entero además de la foto tipo
                    pasaporte.
                  </span>
                  <v-col>
                    <template>
                      <div class="box">
                        <img
                          class="js--image-preview"
                          :src="body.photo"
                          v-if="body"
                        />
                      </div>
                      <div class="buttons upload-options">
                        <label class="custom-file-upload text-color">
                          <v-icon dense color="blue">border_color</v-icon>
                          <input
                            ref="fileuploadBody"
                            type="file"
                            class="image-upload"
                            accept="image/*"
                            @change="getPhotoBody"
                          />
                          Editar
                        </label>
                        <label
                          class="custom-file-upload text-color"
                          @click="clearPhotoBody"
                        >
                          <v-icon dense color="blue">delete</v-icon>
                          Borrar
                        </label>
                      </div>
                    </template>
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col xl="4" sm="12" md="12">
        <h1>Requisitos</h1>
        <p>
          Siga los requisitos que aparecen abajo y después envíe las
          fotografías.
        </p>
        <div>
          <h3>Vestimenta y arreglo personal</h3>
          <p>
            Debe vestirse y arreglarse igual que lo haría para una reunión de
            congregación.
          </p>
          <p>
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
            Los hermanos deben llevar un traje oscuro, si es posible, u otro
            atuendo que sea apropiado para las reuniones en la zona.
          </p>
          <p>
            <v-icon color="red">mdi-close-circle</v-icon>No se permite ropa
            informal
          </p>
        </div>
        <div>
          <h3 class="title-sub">Gafas</h3>
          <p>
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
            Si normalmente usa gafas de prescripción médica, póngaselas para la
            foto.‌
          </p>
          <p>
            <v-icon color="red">mdi-close-circle</v-icon>
            No lleve gafas de sol u otras gafas que no sean de prescripción
            médica
          </p>
        </div>
        <div>
          <h3 class="title-sub">Fondo</h3>
          <p>
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
            Las fotografías deben hacerse en un fondo neutro, de color claro,
            como una pared, tal como suelen requerir los organismos oficiales.‌‌
          </p>
        </div>
        <div>
          <h3 class="title-sub">Postura</h3>
          <p>
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
            Esta fotografía será de cuerpo entero, de la cabeza a los pies. Debe
            colocarse de frente mirando a la cámara y con los dos ojos
            abiertos.‌
          </p>
        </div>
        <div>
          <h3 class="title-sub">Fotografías inadmisibles</h3>
          <p>
            <v-icon color="red">mdi-close-circle</v-icon>
            Fotografías con decorado o accesorios, como sombreros o
            auriculares.‌
          </p>
          <p>
            <v-icon color="red">mdi-close-circle</v-icon>
            Fotografías en las que aparecen otras personas.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div>
          <v-btn color="primary" class="text-none" depressed @click="onSave">
            <v-icon left>
              cloud_upload
            </v-icon>
            Guardar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import participantService from "../../../core/http/participant.service";
import ImagePreviewMixin from "../../../mixins/ImagePreviewMixin.vue";
import awsS3 from "../../../core/utils/imageUpload";

export default {
  data: () => ({
    user: null,
    username: null,
    asignacionesNumericas: [1, 10, 100, 1000, 10000, 100000, 0],
    asignaciones: [
      "Precursor Regular",
      "Precursor Especial",
      "Misionero",
      "Siervo Ministerial",
      "Anciano",
      "S.C.",
      "Publicador",
    ],
    asignacion: null,
    passaport: null,
    body: null,
    email: null,
    referencia: null,
    congregacion: null,
    bautismo: null,
  }),
  created() {

    console.log(process.env.AWS_PROFILE);

    participantService.getById(this.$route.params.id).then((response) => {
      this.user = response.data.participant;
      this.getUser();
      this.asignacion = this.getAsignaciones(this.user.asignacion);
    });
  },
  mixins: [ImagePreviewMixin],
  methods: {
    getUser() {
      let secondName = this.user.ap;

      if (this.user.ac != "") {
        secondName = this.user.ac;
      }

      this.username = `${this.user.am} ${secondName}, ${this.user.n}`;
      this.email = this.user.e;
      this.congregacion = this.user.congregacion;
      this.bautismo = this.user.bautismo;
      this.referencia = this.user.referencia;
    },
    getAsignaciones(asignacionNumerica) {
      let asignacionNumericaString = asignacionNumerica.toString();
      let asignacionesArray = [];
      let asignacionToReturn = "";
      asignacionNumericaString = asignacionNumericaString
        .split("")
        .reverse()
        .join("");
      for (
        let letterPosition = 0;
        letterPosition < asignacionNumericaString.length;
        letterPosition++
      ) {
        if (asignacionNumericaString.charAt(letterPosition) == "1") {
          asignacionesArray.push(this.asignaciones[letterPosition]);
        }
      }
      if (asignacionesArray.length == 0) {
        asignacionToReturn = "Publicador";
      } else {
        for (let i = 0; i < asignacionesArray.length; i++) {
          if (i == 0) {
            asignacionToReturn += asignacionesArray[i];
          } else {
            asignacionToReturn += ", " + asignacionesArray[i];
          }
        }
      }

      return asignacionToReturn;
    },
    getPhotoPassaport(e) {
      if (e) {
        let image = e.target.files[0];
        let payload = {
          contentType: image.type, // you can set it based on the type of image you are uploading like image/png
          fileName: image.name,
          file: null,
          photo: null,
        };

        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          payload.file = e.target.result
            .replace("data:*/*;base64,", "")
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", "");
          payload.photo = e.target.result;
          this.passaport = payload;
        };
      }
    },
    getPhotoBody(e) {
      if (e) {
        let image = e.target.files[0];

        let payload = {
          contentType: image.type, // you can set it based on the type of image you are uploading like image/png
          fileName: image.name,
          file: null,
          photo: null,
        };

        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          payload.file = e.target.result
            .replace("data:*/*;base64,", "")
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", "");
          payload.photo = e.target.result;
          this.body = payload;
        };
      }
    },
    clearPhotoPassaport() {
      this.$refs.fileuploadPassaport.value = null;
      this.passaport = null;
    },
    clearPhotoBody() {
      this.$refs.fileuploadBody.value = null;
      this.body = null;
    },
    onSave() {
      awsS3.uploadImaged(this.passaport).then((response) => {
        console.log(response);
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.title {
  background-color: #4d4947;
  border-radius: 3px 3px 0 0;
  color: white;
}

.badge {
  display: inline-block;
  padding: 0.1em 0.5em;
  font-size: 0.8125em;
  font-weight: 700;
  text-align: center;
  white-space: pre-line;
  vertical-align: baseline;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #fff;
  background-color: #74706d;
}

.bold {
  font-weight: bold;
}

.photo {
  display: block;

  width: 200px;
  height: 200px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: stretch;
}

.js--image-preview {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #e7e6e4;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box {
  display: block;
  width: 225px;
  height: 250px;
  margin: 10px;
  background-color: #e7e6e4;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.upload-options {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  transition: background-color ease-in-out 150ms;
  padding: 0px 0px 0px 10px;
}

.text-color {
  color: #2196f3 !important;
}

.title-sub {
  margin-bottom: 5px;
}
</style>
