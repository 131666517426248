<template id="notification">
  <div>
    <v-snackbar
      v-model="notification.visible"
      :color="notification.type"
      :multi-line="notification.mode === 'multi-line'"
      :top="notification.position === 'top'"
      :bottom="notification.position === 'bottom'"
      :left="notification.position === 'left'"
      :right="notification.position === 'right'"
      :timeout="notification.delay"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large v-if="notification.icon">{{
          notification.icon
        }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ notification.title }}</strong>
          </div>
          <div>{{ notification.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="!notification.timeout" icon @click="triggerClose()">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import notificationStore from "../utils/notificationStore";

export default {
  template: "#notification",
  props: ["notification"],
  data: function() {
    return { timer: null, alert: false };
  },
  created() {
    this.notification.timeout = this.notification.timeout
      ? this.notification.timeout
      : true;
    this.notification.icon = this.notification.icon
      ? this.notification.icon
      : null;
    this.notification.title = this.notification.title
      ? this.notification.title
      : "";
    this.notification.text = this.notification.text
      ? this.notification.text
      : "";
    this.notification.visible = this.notification.visible
      ? this.notification.visible
      : true;
    this.notification.delay = this.notification.delay
      ? this.notification.delay
      : 3500;
    this.notification.position = this.notification.position
      ? this.notification.position
      : "top";
    this.notification.mode = this.notification.mode
      ? this.notification.mode
      : "multi-line";
    this.notification.id = this.uuidv4();

    let timeout = this.notification.timeout;

    if (timeout) {
      let delay = this.notification.delay;
      this.timer = setTimeout(
        function() {
          this.triggerClose(this.notification);
        }.bind(this),
        delay
      );
    }
  },
  methods: {
    triggerClose: function(notification) {
      clearTimeout(this.timer);
      notificationStore.removeNotification(notification);
      notification.visible = false;
    },
    uuidv4: function() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
};
</script>
