<template>
  <v-container fluid>
    <v-row dense>
      <v-col align-self="start">
        <h2>Reporte de participantes asignados</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4">
        <v-select
          :v-slot="{ filter }"
          :items="items"
          v-model="filter.type"
          item-value="value"
          item-text="text"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" align-self="center">
        <v-btn color="primary" dark right @click="search()">
          Buscar
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="table-responsive py-5">
      <v-data-table :headers="headers" :items="data" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  dark
                  right
                  v-if="showButtonExport"
                  @click="exportExcel()"
                >
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import reportService from "../../../core/http/report-info.service";
import userService from "../../../core/http/user.service";
import qs from "qs";
import factory from "../../../core/utils/excelCreationFactory";
import availabilityFactory from "../../../core/utils/createFactoryAvailability";
import dayFactory from "../../../core/utils/daysEnum";
import hourFactory from "../../../core/utils/turnUtils";
import dataFactory from "../../../core/utils/dataFactory";

export default {
  data: () => ({
    filter: { type: 1 },
    items: [
      { text: "Asignado", value: 1 },
      { text: "Sin Asignar", value: 0 },
      { text: "Por turno", value: 2 },
    ],
    headers: [],
    dataheaders: [
      {
        text: "Referencia",
        value: "referencia",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Nombre",
        value: "n",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido Paterno",
        value: "ap",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido Materno",
        value: "am",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Apellido de casada",
        value: "ac",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Email",
        value: "e",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Telefono fijo",
        value: "t",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Celular",
        value: "c",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Circuito",
        value: "circuito",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Congregación",
        value: "congregacion",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
      {
        text: "Lunes",
        value: "lun",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.lun);
        },
        show: true,
      },
      {
        text: "Martes",
        value: "mar",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.mar);
        },
        show: true,
      },
      {
        text: "Miércoles",
        value: "mie",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.mie);
        },
        show: true,
      },
      {
        text: "Jueves",
        value: "jue",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.jue);
        },
        show: true,
      },
      {
        text: "Viernes",
        value: "vie",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.vie);
        },
        show: true,
      },
      {
        text: "Sábado",
        value: "sab",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.sab);
        },
        show: true,
      },
      {
        text: "Domingo",
        value: "dom",
        sortable: false,
        callback: (element) => {
          return availabilityFactory.GetDiasDisponibles(element.dom);
        },
        show: true,
      },
      {
        text: "Fecha de registro",
        value: "fecha_registro",
        align: "center",
        sortable: false,
      },
      {
        text: "Dia",
        value: "dia",
        sortable: false,
        align: "center",
        callback: (element) => {
          return dayFactory.GetDay(element.dia);
        },
        show: true,
      },
      {
        text: "Hora inicio",
        value: "hora_inicio",
        sortable: false,
        align: "center",
        callback: (element) => {
          return hourFactory.convertHour(element.hora_inicio);
        },
        show: true,
      },
      {
        text: "Hora fin",
        value: "hora_fin",
        sortable: false,
        align: "center",
        callback: (element) => {
          return hourFactory.convertHour(element.hora_fin);
        },
        show: true,
      },
      {
        text: "Ubicación",
        value: "ubicacion",
        align: "center",
        sortable: false,
        callback: null,
        show: true,
      },
    ],
    data: [],
    fields: [],
    titles: null,
    showButtonExport: false,
  }),

  components: {},

  computed: {},

  watch: {},

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.data = [];
      let data = {
        asignados: this.filter.type,
        id_ciudad: userService.getCity(),
      };
      let json = qs.stringify({ json: JSON.stringify(data) });

      await reportService.reporte_2(json).then((response) => {
        if (!Array.isArray(response.data.participant)) {
          this.data.push(response.data.participant);
        } else {
          this.data = response.data.participant;
        }

        const dataItems = dataFactory.CreateData(this.dataheaders, this.data);

        this.headers = dataItems.headers;
        this.data = dataItems.data;
        this.showButtonExport = this.data.length > 0;
      });
    },
    exportExcel() {
      this.fields = factory.CreateFields(this.headers, "value");
      this.titles = factory.CreateTitles(this.headers, "value", "text");
      factory.CreateExcel(this.data, this.fields, this.titles, "reporte_asignados");
    },
  },
};
</script>

<style lang="scss" scoped></style>
