import { AUTH_REQUEST, AUTH_REQUEST_TEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, REFRESH_TOKEN, REFRESH_TOKEN_TEST, REQUIRE_PROFILE } from "../actions/auth.js";
import { USER_REQUEST, USER_REQUEST_TEST } from "../actions/user.js";
import apiCall from "../../core/utils/api.js";
import router from '../../router'
import NotificationStore from "../../core/utils/notificationStore";
import convertJson from "../../core/utils/convertJson";
import userService from "../../core/http/user.service.js";

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  hasLoadedOnce: false,
  requireProfile: false,
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  requireProfile: state => state.requireProfile
};

async function UserLoginRequest(data) {
  const json = convertJson.ConvertToJson(data);
  let response = await userService.login(json);

  if (response.data?.status != undefined && response.data?.status == "error") {
    const error = new Error('Usuario o Password Incorrecto');
    error.statusCode = 401;
    NotificationStore.addNotification({
      title: 'Error',
      text: "Usuario o Password Incorrecto",
      type: "error",
      timeout: false,
      position: "top",
    });
    throw error;
  }

  return response;
}

const actions = {

  [AUTH_REQUEST_TEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiCall({ url: "auth", data: user, method: "POST" })
        .then(response => {
          localStorage.setItem("token", response.token);
          localStorage.setItem('auth-user', JSON.stringify(response));
          commit(AUTH_SUCCESS, response.token);
          dispatch(USER_REQUEST_TEST);
          resolve(response);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("token");
          localStorage.removeItem("auth-user");
          reject(err);
        });
    });

  },
  [AUTH_REQUEST]: async ({ commit, dispatch }, data) => {
    try {
      commit(AUTH_REQUEST);
      let response = await UserLoginRequest(data);
      localStorage.setItem("token", response.data);
      commit(AUTH_SUCCESS, response.data);

      data['gettoken'] = true;
      response = await UserLoginRequest(data);

      await dispatch(USER_REQUEST, response.data.id).then((resp) => {
        response = resp;
      });

      return response;

    } catch (err) {
      commit(AUTH_ERROR, err);
      localStorage.removeItem("token");
      localStorage.removeItem("auth-user");
    }
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      commit(REQUIRE_PROFILE, false)
      localStorage.removeItem("token");
      localStorage.removeItem("auth-user");
      localStorage.removeItem("user-permissions");
      router.push('/login');

      resolve();
    });
  },
  [REFRESH_TOKEN_TEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiCall({ url: "token", method: "GET" })
        .then(response => {
          localStorage.setItem("token", response.token);
          commit(AUTH_SUCCESS, response.token);
          resolve(response);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("token");
          localStorage.removeItem("auth-user");
          reject(err);
        });
    });
  },
  [REFRESH_TOKEN]: async ({ commit }) => {
    //const url = "http://iarboledas.info/appeam/dev_appeam/public/api/user/token";
    try {
      commit(AUTH_REQUEST);
      const response = await userService.token() //axios.get(url);

      localStorage.setItem("token", response.token);
      commit(AUTH_SUCCESS, response.token);

      return response;

    } catch (err) {
      commit(AUTH_ERROR, err);
      localStorage.removeItem("token");
      localStorage.removeItem("auth-user");
    }
  },
  [REQUIRE_PROFILE]: ({ commit }, data) => {

    commit(REQUIRE_PROFILE, data)
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = "success";
    state.token = token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  },
  [REQUIRE_PROFILE]: (state, value) => {
    state.requireProfile = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};