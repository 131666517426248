<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
              @keypress.enter.prevent
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showCreateItem()">Nuevo</v-btn>
          </v-card-title>
          <!-- Datatable -->
          <v-data-table :headers="headers" :items="horarios" :search="search">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="showEditItem(item)">
                <v-icon small class="mr-2"> mdi-pencil </v-icon>
              </v-btn>
              <v-btn icon @click="deleteItem(item)">
                <v-icon small class="mr-2"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Dialog -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ action }} Horario</span>
        </v-card-title>
        <v-form @submit.prevent="saveItem">
          <v-card-text>
            <!-- Dialog start time-->
            <v-dialog
              ref="StartTimeDialog"
              v-model="startTimeDialog"
              :return-value.sync="form.hora_inicio"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.hora_inicio"
                  prepend-icon="mdi-clock-time-four-outline"
                  label="Hora inicio"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimeDialog"
                v-model="form.hora_inicio"
                full-width
                format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startTimeDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.StartTimeDialog.save(form.hora_inicio)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <!-- Dialog start time-->
            <!-- Dialog end time-->
            <v-dialog
              ref="EndTimeDialog"
              v-model="endTimeDialog"
              :return-value.sync="form.hora_fin"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.hora_fin"
                  prepend-icon="mdi-clock-time-four-outline"
                  label="Hora Fin"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimeDialog"
                v-model="form.hora_fin"
                full-width
                format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endTimeDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.EndTimeDialog.save(form.hora_fin)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <!-- Dialog end time-->
            <!-- <v-combobox
              v-model="form.ciudad"
              :items="ciudades"
              item-text="nombre"
              item-value="id"
              label="Ciudad"
              clearable
            ></v-combobox>-->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" @click="dialog = !dialog" text>
              Cancelar
            </v-btn>
            <v-btn color="success" @click="dialog = !dialog" text type="submit">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">¿Quiere borrar el turno?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fin Dialog -->
  </v-container>
</template>
<script>
import userService from "../../core/http/user.service";
import scheduleService from "../../core/http/schedule.service";
import convertJson from "../../core/utils/convertJson";
import notificationStore from "../../core/utils/notificationStore";
import hourFactory from "../../core/utils/turnUtils";

export default {
  data() {
    return {
      dialog: false,
      action: "Crear",
      search: "",
      startTimeDialog: false,
      endTimeDialog: false,
      dialogDelete: false,
      form: {
        id: -1,
        id_ciudad: null,
        hora_inicio: "",
        hora_fin: "",
        ciudad: "",
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Hora inicio", value: "hora_inicio" },
        { text: "Hora fin", value: "hora_fin" },
        { text: "", value: "actions" },
      ],
      ciudades: [],
      horarios: [],
      editedIndex: null,
    };
  },
  methods: {
    formatHours(data) {
      return hourFactory.convertHour(data);
    },
    showEditItem(item) {
      this.dialog = true;
      this.action = "Editar";
      this.form = Object.assign({}, item);
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.form = Object.assign(
          {},
          {
            id: null,
            id_ciudad: null,
            hora_inicio: "",
            hora_fin: "",
            ciudad: "",
          }
        );
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      const id = this.editedIndex;

      await scheduleService.delete(id).then(() => {
        this.closeDelete();
        notificationStore.showNotification(
          "Operación exitosa",
          "Horario eliminado correctamente",
          "success"
        );
        this.getEntities();
      });
    },
    showCreateItem() {
      this.dialog = true;
      this.action = "Crear";
      this.form = {
        id: -1,
        id_ciudad: null,
        hora_inicio: null,
        hora_fin: null,
        ciudad: null,
      };
    },
    async saveItem() {
      this.form.hora_inicio = this.tConvert(this.form.hora_inicio, 0);
      this.form.hora_fin = this.tConvert(this.form.hora_fin, 0);
      const item = {
        id_ciudad: this.form.id_ciudad,
        hora_inicio: this.form.hora_inicio,
        hora_fin: this.form.hora_fin,
        ciudad: this.form.ciudad,
      };

      if (this.form.id > -1) {
        await this.updateItem(this.form.id, item);
      } else {
        await this.createItem(item);
      }

      await this.getEntities();
    },
    async createItem(item) {
      item.id_ciudad = userService.getCity();
      const json = convertJson.ConvertToJson(item);

      await scheduleService.create(json).then(() => {
        notificationStore.showNotification(
          "Operación exitosa",
          "Horario creado correctamente",
          "success"
        );
      });
    },
    async updateItem(id, item) {
      const json = convertJson.ConvertToJson(item);

      await scheduleService.update(id, json).then(() => {
        notificationStore.showNotification(
          "Operación exitosa",
          "Horario modificado correctamente",
          "success"
        );
      });
    },
    tConvert(time, format) {
      switch (format) {
        case 0:
          time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
          if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time.splice(1, 1, "");
          }
          break;
        case 1:
          time = "0" + time;
          time = time
            .toString()
            .match(/^([01]\d|2[0-3])([0-5]\d)(:[0-5]\d)?$/) || [time];
          if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time.splice(1, 0, ":");
          }
          break;
      }
      // Check correct time format and split into components
      return time.join(""); // return adjusted time or original string
    },
    async getEntities() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);

      const horarios = await scheduleService.getByCity(json);
      this.horarios = horarios.data.horarios;
      this.horarios.forEach((element) => {
        element.hora_inicio = this.formatHours(element.hora_inicio);
        element.hora_fin = this.formatHours(element.hora_fin);
      });
    },
  },
  async created() {
    await this.getEntities();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
