import store from '../store';
import { REQUIRE_PROFILE } from "../store/actions/auth.js";

export default {
    authorize(requireAuthentication, requiredPermissions) {
        let result = 'authorized'
        const accessPage = requiredPermissions || '';
        const permissions = JSON.parse(localStorage.getItem('user-permissions'));
        store.dispatch(REQUIRE_PROFILE, accessPage.length > 0);

        if (permissions == null) {
            return 'notAuthorized';
        }

        if (requireAuthentication && !store.getters.isAuthenticated) {
            return 'loginIsRequired';
        }

        if (requireAuthentication && store.getters.isAuthenticated && accessPage.length === 0) {
            return 'authorized';
        }

        if (accessPage.length) {
            const authorized = this.searchPermission(permissions, accessPage);

            return authorized ? 'authorized' : 'notAuthorized';
        }

        return result;
    },
    searchPermission(permissions, namePermission) {
        let authorized = false;

        for (let item of permissions) {
            if (item.ref != undefined && item.ref.toLowerCase() == namePermission.toLowerCase()) {
                authorized = true;

                break;
            }

            if (item.childrens !== undefined && item.childrens.length === 0) {
                this.searchPermission(item.childrens, namePermission);
            }
        }

        return authorized
    }
}