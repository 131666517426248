import Axios from 'axios';

const RESOURCE_NAME = "/access";

export default {
    get() {
        return Axios.get(RESOURCE_NAME);
    },
    getbyId(id) {
        return Axios.get(`${RESOURCE_NAME}/${id}`);
    },
    create(data) {
        return Axios.post(`${RESOURCE_NAME}?${data}`);
    },
    update(id, data) {
        return Axios.put(`${RESOURCE_NAME}/${id}?${data}`);
    },
    delete(id) {
        return Axios.delete(`${RESOURCE_NAME}/${id}`);
    },
    getByCity(json){
        return Axios.get(`${RESOURCE_NAME}?${json}`);
    },
    pinf(report_id){
        return Axios.get(`${RESOURCE_NAME}_pinf/${report_id}`);
    }
}