<template>
  <v-container fluid id="container-main">
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" sm="6" md="6" lg="3">
            <validation-provider name="Ubicacion" rules="required">
              <v-select
                :v-slot="{ item }"
                :items="localizations"
                label="Ubicacion"
                v-model="item.ubicacionId"
                item-value="id"
                item-text="nombre"
                @change="changeUbication(item.ubicacionId)"
              >
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-container dense v-if="calendar.schedule != null">
          <div class="container table-responsive py-5">
            <table class="table table-assigned">
              <thead>
                <tr>
                  <th>Turno</th>
                  <th v-for="(day, i) in daysOfWeek" :key="i">
                    {{ day.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="turn in calendar.turns">
                  <template v-for="row in turn.maxRowSpanIndex">
                    <tr v-if="row.key" :key="row.key">
                      <td
                        class="hour"
                        :rowspan="turn.maxRowSpan"
                        v-if="row.position == 0"
                      >
                        <span>{{ turn.name }} {{ turn.key }}</span>
                      </td>
                      <td
                        v-for="(day, dayIndex) in daysOfWeek"
                        :key="dayIndex"
                        :set="
                          ((participant = getDataArray(
                            row.index,
                            dayIndex,
                            row.position
                          )),
                          (element = getElementArray(row.index, dayIndex)))
                        "
                        :class="
                          row.position == 0
                            ? 'border-table-top'
                            : turn.maxRowSpan == 1
                            ? 'border-table-bottom'
                            : ''
                        "
                      >
                        <v-btn
                          elevation="2"
                          x-small
                          icon
                          @click="
                            openAssigened(row.index, dayIndex, row.position)
                          "
                          v-if="participant.name != ''"
                          class="search-button"
                        >
                          <v-icon small color="blue">search</v-icon>
                        </v-btn>
                        <v-btn
                          elevation="2"
                          x-small
                          icon
                          @click="deleteItem(row.index, dayIndex, row.position)"
                          class="delete-button"
                          v-if="participant.name != ''"
                        >
                          <v-icon small color="red">delete</v-icon>
                        </v-btn>
                        <span
                          v-if="participant.id_participante != 0"
                          :class="`${
                            participant.capitan ? 'text-bold' : 'text-normal'
                          } text-info`"
                          >{{ participant.name }}</span
                        >
                        <div
                          v-if="participant.name != ''"
                          class="field-assigned"
                        ></div>
                        <v-btn
                          v-if="
                            participant.id == 0 &&
                            row.position == element.count &&
                            element.available &&
                            row.position < element.capacity
                          "
                          elevation="2"
                          icon
                          x-small
                          @click="
                            openNewDialog(row.index, dayIndex, row.position)
                          "
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>

          <template>
            <v-dialog v-model="dialogDelete" max-width="500px" persistent>
              <v-card>
                <v-card-title class="headline"
                  >¿Borrar al participante asignado?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAssigned" max-width="500px" persistent>
              <v-card>
                <v-card-title class="headline">Participante</v-card-title>
                <v-card-text v-if="assignedShow != null">
                  <v-row align="center" class="mx-0 mt-1">
                    <label class="text-bold text-zoom"
                      >Nombre:
                      <span class="text-normal">{{ assignedShow.name }}</span>
                    </label>
                  </v-row>
                  <v-row align="center" class="mx-0">
                    <label class="text-bold text-zoom"
                      >Capitan:
                      <span class="text-normal">{{
                        assignedShow.capitan
                      }}</span>
                    </label>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogAssigned = false"
                    >OK</v-btn
                  >
                  <v-btn
                    color="red darken-1"
                    text
                    @click="confirmDeleteAssigened"
                    >Eliminar
                    <v-icon small color="red">delete</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <validation-observer ref="observer">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <form @submit.prevent="submit">
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="6">
                            <validation-provider name="Turno" rules="required">
                              <v-autocomplete
                                :v-slot="{ item }"
                                :items="turnsRef"
                                label="Turno"
                                v-model="item.turnoId"
                                item-value="id"
                                item-text="name"
                                @change="changeTurn()"
                              >
                              </v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <validation-provider
                              name="Candidato"
                              rules="required"
                            >
                              <v-autocomplete
                                :v-slot="{ item }"
                                :items="candidatesRef"
                                label="Candidato"
                                v-model="item.candidateId"
                                item-value="id"
                                item-text="name"
                              >
                              </v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="item.capitan"
                              label="Capitan"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="invalid"
                      text
                      @click="saveTemp"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-dialog>
          </template>
        </v-container>
        <v-row align="center" justify="end">
          <v-card-actions v-if="calendar.schedule != null" a>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeMain">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveMain">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-container>
    </v-card-text>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import notificationStore from "../../core/utils/notificationStore";
import weekend from "../../core/utils/daysEnum";
import assignedService from "../../core/http/assigned.service";
import candidateService from "../../core/http/candidates.service";
import convertJson from "../../core/utils/convertJson";
import userService from "../../core/http/user.service";
import locationService from "../../core/http/location.service";
import turnService from "../../core/http/turn.service";
import participants from "../../core/http/participant.service";

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

export default {
  data: () => ({
    headers: [
      {
        text: "Participante",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Turno", value: "turn" },
      { text: "", value: "actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    dialogAssigned: false,
    search: "",
    data: [],
    assigneds: [],
    candidates: [],
    candidatesFilters: [],
    editedIndex: -1,
    maxRowSpan: 0,
    item: {
      ubicacionId: null,
      candidateId: null,
      turnoId: null,
    },
    ubication: null,
    formTempSave: null,
    calendar: { data: [], assigned: [], turns: [], schedule: null },
    turns: [],
    localizations: [],
    assigned: false,
    daysOfWeek: [],
    saveArrayTemp: [],
    deleteArrayTemp: [],
    assignedShow: null,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    getDays() {
      this.daysOfWeek = [];
      const array = [];
      //var days = Object.entries(weekend.Days).map(([k, v]) => ({ [k]: v }));
      Object.getOwnPropertyNames(weekend.Days).forEach(function (val, index) {
        array.push({ index: index, name: val, value: weekend.Days[val] });
      });
      this.daysOfWeek = array;
    },
    closeMain() {
      this.calendar = { data: [], assigned: [], turns: [], schedule: null };
      this.item.ubicacionId = null;
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.clear();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    clear() {
      this.editedIndex = -1;
      (this.item.turnoId = null),
        (this.item.candidateId = null),
        (this.item.capitan = false);
    },
    async changeTurn() {
      const turn =
        this.filterByProperty(
          this.calendar.schedule,
          "turn_id",
          this.item.turnoId
        )[0] || null;

      if (turn) {
        const position = turn.position;
        await this.filterCandidates(position.x, position.y);
      }
    },
    async openNewDialog(turnIndex, dayIndex, position) {
      await this.getParticipants(this.ubication.id_ciudad)
      this.editedIndex = { x: turnIndex, y: dayIndex, z: position };
      await this.filterCandidates(this.editedIndex.x, this.editedIndex.y);
      this.dialog = true;
    },
    async filterCandidates(turnIndex, dayIndex) {
      //await this.getCandidates(this.item.ubicacionId);
      const element = this.getElementArray(turnIndex, dayIndex);
      ///Validacion para no tener mas de un candidato por turno

      this.candidates = this.candidates.filter(function(candidate) {
        return (
          element.assigned.filter(function(assign) {
            return candidate.id == assign.id_participante;
          }).length == 0
        );
      });

      this.item.turnoId = element.turn_id || null;
    },
    deleteItem(turnIndex, dayIndex, position) {
      this.editedIndex = { x: turnIndex, y: dayIndex, z: position };
      this.dialogDelete = true;
    },
    getCity() {
      return this.$store.getters.getCity;
    },
    orderTurns(array) {
      array.sort(function (a, b) {
        if (a.hora_inicio === b.hora_inicio) {
          return b.hora_fin - a.hora_fin;
        }

        return a.dia > b.dia && a.hora_inicio > b.hora_inicio ? 1 : -1;
      });

      array.reverse();
    },
    showNotification(title, text, type) {
      notificationStore.addNotification({
        title: title,
        text: text,
        type: type,
        timeout: true,
        position: "bottom",
      });
    },
    setData(data, callback) {
      let dataRef = [];

      for (let index = 0; index < data.length; index++) {
        dataRef.push({
          id: data[index].id,
          name: callback(data[index]),
          count: data[index].asignados || 0,
        });
      }

      return dataRef;
    },
    getName(item) {
      return `${item.n} ${item.ap} ${item.ac === "" ? item.am : item.ac}`;
    },
    getTurnName(item) {
      return `${weekend.GetDay(item.dia)} ${this.convertHour(
        item.hora_inicio
      )} - ${this.convertHour(item.hora_fin)}`;
    },
    getTurnHour(item) {
      return `${this.convertHour(item.hora_inicio)} - ${this.convertHour(
        item.hora_fin
      )}`;
    },
    convertHour(hour) {
      const index = hour.toString().length - 2;
      const min = hour.toString().substr(index, hour.toString().length);
      const hr = hour.toString().substr(0, index);

      return `${hr}:${min}`;
    },
    toggle() {
      if (this.assigned) {
        this.candidatesFilters = this.candidates.filter((x) => x.count > 0);
      } else {
        this.candidatesFilters = this.candidates.filter((x) => x.count == 0);
      }
    },
    filterObject(item, text) {
      return (
        this.getName(item)
          .toLocaleLowerCase()
          .indexOf(text.toLocaleLowerCase()) > -1
      );
    },
    getUniqueElements(arrayObject, arrayProperty) {
      let seen = Object.create(null);
      const result = arrayObject.filter((o) => {
        var key = arrayProperty.map((k) => o[k]).join("|");

        if (!seen[key]) {
          seen[key] = true;

          return true;
        }
      });

      return result;
    },
    getMaxRowSpan(array, property) {
      return Math.max.apply(
        Math,
        array.map((o) => {
          return o[property] || 0;
        })
      );
    },
    getMaxItem(array, property) {
      if (array.length == 0) return null;

      return array.reduce(function (prev, current) {
        return prev[property] > current[property] ? prev : current;
      });
    },
    getMaxItemCallBack(array, callback) {
      if (array.length == 0) return null;

      return array.reduce(function (prev, current) {
        return callback(prev) > callback(current) ? prev : current;
      });
    },
    getDataArray(turnIndex, dayIndex, position) {
      const element = this.calendar.schedule[turnIndex][dayIndex];

      if (element) {
        const assigned = element.assigned[position] || null;

        return {
          id: assigned?.id || 0,
          name: assigned ? this.getName(assigned) : "",
          capitan: assigned ? assigned.capitan : false,
        };
      }

      return { id: 0, name: "" };
    },
    getElementArray(turnIndex, dayIndex) {
      var element = this.calendar.schedule[turnIndex][dayIndex];

      return element;
    },
    refreshMaxRowm(turn_index, turns_array, assigned_array) {
      const turn = this.calendar.turns[turn_index];
      const turnsAvailabe =
        turns_array.filter(
          (x) =>
            x.hora_inicio == turn.hora_inicio && x.hora_fin == turn.hora_fin
        ) || [];
      const turnsValid = [];
      const assigned = assigned_array.filter(function (assign) {
        return (
          turnsAvailabe.filter(function (turn) {
            return turn.turn_id == assign.turn_id;
          }).length == 0
        );
      });
      const groupTurns = this.groupByKey(assigned, "turn_id") || [];
      const grps = Object.keys(groupTurns).map((key) => [groupTurns[key]]);

      for (let index = 0; index < grps.length; index++) {
        const data = grps[index][0];
        turnsValid.push({
          id: data[0].turn_id,
          count: data[0].count,
        });
      }

      const maxAssigned = this.getMaxItemCallBack(
        turnsValid,
        (x) => x.count
      ) || { id: 0, count: 0 };
      const maxCapacity = this.getMaxItemCallBack(
        turnsAvailabe,
        (x) => x.capacidad
      ) || { id: 0, capacidad: 0 };
      const maxRow =
        maxAssigned.count < maxCapacity.capacidad || maxCapacity.capacidad == 0
          ? maxAssigned.count + 1
          : maxCapacity.capacidad;
      this.calendar.turns[turn_index]["maxRowSpan"] = maxRow;
      this.calendar.turns[turn_index]["maxRowSpanIndex"] = this.createKeyIndex(
        turn_index,
        maxRow
      );
    },
    createKeyIndex(turn_index, maxRow) {
      let arrayIndex = [];

      for (let index = 0; index < maxRow; index++) {
        arrayIndex.push({
          index: turn_index,
          position: index,
          key: `${turn_index}-${index}`,
        });
      }

      return arrayIndex;
    },
    makeSchedule(turns_array, assigned_array) {
      const turns = this.getUniqueElements(turns_array, [
        "hora_inicio",
        "hora_fin",
      ]);

      this.calendar.turns = turns.map((x, index) => ({
        index: index,
        hora_fin: x.hora_fin,
        hora_inicio: x.hora_inicio,
        dia: x.dia,
        name: this.getTurnHour(x),
      }));

      let schedule = Array.from(
        Array(turns.length),
        () => new Array(weekend.Days.length)
      );

      for (
        let turn_index = 0;
        turn_index < this.calendar.turns.length;
        turn_index++
      ) {
        const turn = this.calendar.turns[turn_index];

        for (
          let weekend_index = 0;
          weekend_index < this.daysOfWeek.length;
          weekend_index++
        ) {
          const day = this.daysOfWeek[weekend_index];
          const turnOfDay =
            turns_array.filter(
              (x) =>
                x.dia == day.value &&
                x.hora_inicio == turn.hora_inicio &&
                x.hora_fin == turn.hora_fin
            )[0] || null;

          const turnIdSearch = turnOfDay?.id || 0;
          var participantInTurn = assigned_array.filter(
            (x) => x.id_turno == turnIdSearch
          );

          schedule[turn_index][weekend_index] = {
            nameOfDay: day.name,
            numberOfDay: day.value,
            startTime: turn.hora_inicio,
            endTime: turn.hora_fin,
            available: turnOfDay != null,
            turn_id: turnOfDay?.id || null,
            capacity: turnOfDay?.capacidad || null,
            position: { x: turn_index, y: weekend_index },
            assigned: participantInTurn.map((x) => {
              return {
                id: x.id,
                id_turno: x.id_turno,
                hora_inicio: x.hora_inicio,
                hora_fin: x.hora_fin,
                ubicacion: x.ubicacion,
                id_participante: x.id_participante,
                capitan: x.capitan || false,
                n: x.n,
                ap: x.ap,
                am: x.am,
                ac: x.ac,
                id_circuito: x.id_circuito,
                type: "fixed",
              };
            }),
            count: participantInTurn.length,
          };
        }
        this.refreshMaxRowm(turn_index, turns_array, schedule[turn_index]);
      }

      this.calendar.schedule = schedule;
    },
    groupByKey(array, key) {
      if (array.length == 0) return null;

      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },
    deleteItemConfirm() {
      const element =
        this.calendar.schedule[this.editedIndex.x][this.editedIndex.y];
      const elementDelete = element.assigned[this.editedIndex.z];
      element.assigned.splice(this.editedIndex.z, 1);
      element.count = element.assigned.length || 0;
      this.refreshMaxRowm(
        this.editedIndex.x,
        this.turns,
        this.calendar.schedule[this.editedIndex.x]
      );
      this.dialogDelete = false;
      this.dialogAssigned = false;

      if (elementDelete.type == "fixed") {
        this.deleteArrayTemp.push(elementDelete.id);
      } else {
        const index = this.saveArrayTemp.indexOf(
          (x) =>
            x.id_participante == elementDelete.id &&
            x.id_turno == element.turn_id
        );

        if (index !== -1) {
          this.saveArrayTemp.splice(index, 1);
        }
      }
    },
    saveTemp() {
      let data =
        this.filterByProperty(
          this.calendar.schedule,
          "turn_id",
          this.item.turnoId
        )[0] || null;

      if (data) {
        const candidate =
          this.candidates.filter((x) => x.id == this.item.candidateId)[0] ||
          null;

        data.assigned.push({
          ac: candidate.ac,
          am: candidate.am,
          ap: candidate.ap,
          id_circuito: candidate.id_circuito,
          id_participante: candidate.id,
          n: candidate.n,
          type: "temp",
          capitan: this.item.capitan || false,
        });

        data.count = data.assigned.length;
        this.refreshMaxRowm(
          this.editedIndex.x,
          this.turns,
          this.calendar.schedule[this.editedIndex.x]
        );

        this.saveArrayTemp.push({
          id_participante: candidate.id,
          id_turno: data.turn_id,
          capitan: this.item.capitan || false,
        });
      }

      this.close();
    },
    filterByProperty(array, prop, value) {
      var filtered = [];
      const nameObject = "object";
      for (var i = 0; i < array.length; i++) {
        var obj = array[i];

        for (var key in obj) {
          if (typeof obj[key] == nameObject) {
            var item = obj[key];
            if (item[prop] == value) {
              filtered.push(item);
            }
          }
        }
      }

      return filtered;
    },
    async saveMain() {
      for (let index = 0; index < this.saveArrayTemp.length; index++) {
        const element = this.saveArrayTemp[index];
        const asssigned = {
          id_turno: element.id_turno,
          id_participante: element.id_participante,
          capitan: element.capitan || false,
        };

        const json = convertJson.ConvertToJson(asssigned);

        await assignedService.create(json).then(() => {
          this.showNotification(
            "Registro exitoso",
            "Asignación correcta",
            "success"
          );
        });
      }

      for (let index = 0; index < this.deleteArrayTemp.length; index++) {
        const element = this.deleteArrayTemp[index];
        await assignedService.delete(element).then(() => {
          this.showNotification(
            "Registro exitoso",
            "Asignación correcta",
            "success"
          );
        });
      }

      this.deleteArrayTemp = [];
      this.saveArrayTemp = [];
    },
    async getCandidates(ubicationId) {
      var data = {
        id_ciudad: userService.getCity(),
        id_ubicacion: ubicationId,
      };
      const json = convertJson.ConvertToJson(data);

      await candidateService.getByCity(json).then((response) => {
        this.candidates = response.data.candidatos;
      });

      this.toggle();
    },
    async getLocalization() {
      const data = { id_ciudad: userService.getCity() };
      const json = convertJson.ConvertToJson(data);
      await locationService.getByCity(json).then((response) => {
        this.localizations = response.data.ubicacions;
      });
    },
    async changeUbication(ubication_id) {
      this.ubication = this.localizations.find((f) => f.id == ubication_id);
      await this.getTurn(ubication_id);
      await this.getAssigned(ubication_id);
      this.getDays();
      this.calendar.data = this.turns;
      this.calendar.assigned = this.assigned;
      this.makeSchedule(this.calendar.data, this.calendar.assigned);
    },
    async getParticipants(ubicationId) {
      var data = {
        id_ciudad: ubicationId,
      };

      const json = convertJson.ConvertToJson(data);

      await participants.getByCity(json).then(response => {
        this.candidates = response.data.participant
      })
    },
    async getTurn(ubication_id) {
      var data = {
        id_ciudad: userService.getCity(),
        id_ubicacion: ubication_id,
      };
      const json = convertJson.ConvertToJson(data);

      await turnService.getByCity(json).then((response) => {
        this.turns = response.data.turnos;
        this.orderTurns(this.turns);
      });
    },
    async getAssigned(ubicationId) {
      this.data = [];
      var temp = {
        id_ciudad: userService.getCity(),
        id_ubicacion: ubicationId,
      };
      const json = convertJson.ConvertToJson(temp);

      await assignedService.getByCity(json).then((response) => {
        this.assigned = response.data.assignado;
      });
    },
    openAssigened(turnIndex, dayIndex, positionIndex) {
      const element = this.getElementArray(turnIndex, dayIndex);
      const participant = element.assigned[positionIndex];
      this.assignedShow = {
        name: this.getName(participant),
        capitan: participant.capitan == 1 ? "Si" : "No",
      };
      this.editedIndex = { x: turnIndex, y: dayIndex, z: positionIndex };
      this.dialogAssigned = true;
    },

    confirmDeleteAssigened() {
      this.dialogDelete = true;
    },
    sortTurns() {
      return this.turns.sort(function (a, b) {
        return a.dia - b.dia;
      });
    },
  },
  computed: {
    formTitle() {
      return "Nueva asignación";
    },
    turnsRef() {
      return this.setData(this.sortTurns(), (x) => this.getTurnName(x));
    },
    candidatesRef() {
      let candidate = this.setData(this.candidates, (x) => this.getName(x));
      return candidate.sort((a, b) => a.name.localeCompare(b.name));
    },
    calendars() {
      return this.calendar;
    },
  },
  async created() {
    await this.getLocalization();
  },
};
</script>
<style>
.container {
  max-width: 100%;
}
.table-assigned {
  font-family: sans-serif;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  margin-bottom: 50px;
}
.table-assigned thead tr th {
  background: #626e7e;
  color: #fefeff;
  padding: 0.5em;
  overflow: hidden;
}
.table-assigned thead tr th:first-child {
  border-radius: 3px 0 0 0;
}
.table-assigned thead tr th:last-child {
  border-radius: 0 3px 0 0;
}
.table-assigned thead tr th .day {
  display: block;
  font-size: 1.2em;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 auto 5px;
  padding: 5px;
  line-height: 1.8;
}
.table-assigned thead tr th .day.active {
  background: #d1d5db;
  color: #626e7e;
}
.table-assigned thead tr th .short {
  display: none;
}
.table-assigned thead tr th i {
  vertical-align: middle;
  font-size: 2em;
}
.table-assigned tbody tr {
  background: #d1d5db;
}
.table-assigned tbody tr:nth-child(odd) {
  background: #c8cdd4;
}
.table-assigned tbody tr:last-child td {
  border-bottom: 1px solid #626e7e;
}
.border-table-top {
  border-top: 1px solid #626e7e;
}
.border-table-bottom {
  border-bottom: 1px solid #626e7e;
}
.table-assigned tbody tr td {
  text-align: center;
  vertical-align: middle;
  border-left: 1px solid #626e7e;
  position: relative;
  height: 32px;
  cursor: pointer;
}
.table-assigned tbody tr td:last-child {
  border-right: 1px solid #626e7e;
}
.table-assigned tbody tr td.hour {
  font-size: 1.8em;
  padding: 0;
  color: #626e7e;
  background: #fff;
  border-bottom: 1px solid #626e7e;
  border-collapse: separate;
  min-width: 100px;
  cursor: default;
}
.text-zoom {
  font-size: 15px;
}
.table-assigned tbody tr td.hour span {
  display: block;
}
.field-assigned {
  float: left;
  margin-left: 10px;
}
.field-assigned span {
  margin-left: 5px;
}
.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}
.search-button {
  display: none;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
  .table-assigned tbody tr td.hour {
    font-size: 80%;
  }
  .table-assigned thead tr th .long {
    display: none;
  }
  .table-assigned thead tr th .short {
    display: block;
  }
  .table-assigned tbody tr td.hour span {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
  }
}
@media (max-width: 800px) {
  .table-assigned thead tr th {
    font-size: 70%;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-button {
    display: block;
  }
  .delete-button,
  .text-info {
    display: none;
  }
  .table-assigned thead tr th .day {
    display: block;
    font-size: 1.5em;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 auto 5px;
    padding: 5px;
  }
  .table-assigned thead tr th .day.active {
    background: #d1d5db;
    color: #626e7e;
  }
  .table-assigned tbody tr td.hour {
    font-size: 0.8em;
  }
  .table-assigned tbody tr td.hour span {
    transform: translateY(16px) rotate(270deg);
    -webkit-transform: translateY(16px) rotate(270deg);
    -moz-transform: translateY(16px) rotate(270deg);
  }
  .field-assigned {
    font-size: 0.5em;
  }
  #container-main {
    padding: 8px;
  }
  .search-button {
    text-align: center;
    vertical-align: middle;
  }
}
</style>
