<template>
  <v-container>
    <v-row justify="center">
      <v-col
        xs="12"
        sm="6"
        md="4"
        lg="3"
        align="center"
        v-for="(form, index) in indice"
        :key="index"
      >
        <v-card
          :color="form.color"
          dark
          align="start"
          width="350"
          height="200"
          elevation="15"
          style="display:flex; flex-direction:column; justify-content:space-between;"
          v-appAuthorize="form.authorize"
        >
          <v-card-title class="headline">
            {{ form.name }}
          </v-card-title>
          <v-card-subtitle>{{ form.description }}</v-card-subtitle>
          <v-card-actions>
            <v-btn text dark :to="form.path">
              Ir a Reporte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "index",
  components: {},
  methods: {},
  computed: mapState({
    indice: (state) => state.user.indiceReport,
    isProfileLoaded: (state) => state.user.isFinishReading,
  }),
};
</script>

<style lang="scss" scoped></style>
