<template>
  <v-footer height="auto" app padless class="justify-center pl-0">
    <v-row justify="center" no-gutters>
      <v-col class="lighten-2 py-4 text-center black--text" cols="12">
        {{ new Date().getFullYear() }} — version 1.1.0 <strong>PPEAM</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "userfooter",
};
</script>
